import React from 'react';
import { DeleteOutlined, GatewayOutlined, QuestionOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Popover, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import GoogleMapReact from 'google-map-react';
import dayjs from 'dayjs';
import { filter, isEmpty, isUndefined, map as _map, once } from 'underscore';
import debounce from 'lodash.debounce';
import Toolbar from './toolbar';
import { Asset, CenterMapMarker, GeofenceMapMarker, currentButton, Marker, PathDot, PathInfo, PathPosition, RoadArrow } from './elements/elements';
import redMarker from './assets/images/google-map-icon-red.png';
import greenMarker from './assets/images/google-map-icon-green.png';
import { getDistanceFromLatLonInKm, getMinumumDistanceBetweenArrowsFromZoom, getSplitNumberByZoom } from './utils/mapUtils';
import { MAP_MODE_DOTS, MAP_MODE_LINE } from '../../pages/Map';

import './styles.scss';

const ASSET_CLICK_ZOOM_LEVEL = 16; // Zoom level for Google Map when an asset is clicked
const SHOW_GEOFENCE_MARKERS_THRESHOLD = 9; // Zoom level above which to show geofence markers

class Map extends React.Component {
    constructor(props) {
        super(props);
        this._googleMap = null;
        this.playPauseInterval = null;
        let { path } = props;
        if (props.allowMultipleRangeSlider) {
            path = this.calculateNewPath(path, props.sliderRangeMinValue, props.sliderRangeMaxValue);
        }
        this.referenceSearchInput = this.referenceSearchInput.bind(this);
        this.timelineIncreaseOptions = [
            {
                label: '15 sec',
                value: 15,
            },
            {
                label: '30 sec',
                value: 30,
            },
            {
                label: '1 min',
                value: 60,
            },
            {
                label: '5 min',
                value: 60 * 5,
            },
            {
                label: '15 min',
                value: 60 * 15,
            },
        ];

        /**
         * getButtonDefaultSelected is a function that returns the defaultSelected property of a button based on its key
         * @param {string} buttonKey - the key of the button to find
         * @returns {boolean | undefined} - the defaultSelected property of the button or undefined if not found
         */
        const getButtonDefaultSelected = (buttonKey) => {
            if (props && props.mapToolBarButtons) {
                const button = props.mapToolBarButtons.find(({ key }) => key === buttonKey);
                return button ? button.defaultSelected : undefined;
            }

            return undefined;
        };

        const mapToolBarInitialState = {
            showAssetsList: false,
            showInfoBox: getButtonDefaultSelected('info-box') || false,
            showTraffic: false,
            drawGeoFence: false,
            showCenterMapInput: false,
            showSatelliteMode: false,
            showHeatMap: false,
            showVideoSearch: (props && props.searchPolygonPath.length > 0),
            showPlayPause: false,
            showLive: false,
        };

        this.state = {
            key: '0',
            ...mapToolBarInitialState,
            center: props.center,
            heatMapInstance: null,
            showGeoFence: false,
            geoFenceCords: [],
            mapComponentKey: 0,
            map: null,
            maps: null,
            geoFencePolygon: [],
            sliderValue: props.sliderValue || 95,
            sliderRangeMinValue: props.sliderRangeMinValue || 10,
            sliderRangeMaxValue: props.sliderRangeMaxValue || 80,
            path: path || [],
            mapAssetDate: props.mapDate || dayjs().format('DD/MM/YYYY'),
            sliderChangeTimeout: 0,
            currentTime: 100,
            geoFencePolyLines: [],
            mapPolyLines: null,
            polyLineMarkers: [],
            assetPathLines: [],
            assetPath: null,
            drawingManager: null,
            mapHasPolygons: false,
            drawingShape: [],
            selectedIncreaseOption: this.timelineIncreaseOptions[0],
            searchInputValue: '',
        };

        this.debounceMapOnChange = debounce(this.mapOnChange, 500);

    }

    componentWillReceiveProps(nextProps) {
        const { sliderValue } = nextProps;
        const { sliderValue: stateSliderValue } = this.state;
        if (stateSliderValue !== sliderValue && !isNaN(sliderValue)) {
            this.setState({ sliderValue });
        }
    }

    componentDidMount() {
        const { onMounted } = this.props;
        onMounted(this);
    }

    componentDidUpdate(prevProps) {
        const { map } = this.state;
        const {
            path, allowMultipleRangeSlider, sliderRangeMinValue, sliderRangeMaxValue, centreMapLatLong,
        } = this.props;

        if (prevProps.path !== path) {
            if (allowMultipleRangeSlider && path) {
                const newPath = this.calculateNewPath(path, sliderRangeMinValue, sliderRangeMaxValue);
                this.setState({ path: newPath }, () => this.refreshMap());
            } else {
                this.setState({ path });
            }
        }

        if (prevProps.centreMapLatLong != centreMapLatLong) {
            map.setCenter({
                lat: Number(centreMapLatLong.lat),
                lng: Number(centreMapLatLong.lng),
            });
            map.setZoom(ASSET_CLICK_ZOOM_LEVEL);
        }
    }

    toggleTrafficLayer = () => {
        const { showTraffic } = this.state;
        this.setState({ showTraffic: !showTraffic });
    };

    toggleHeatMapLayer = () => {
        const {
            showHeatMap, heatMapInstance, map, maps,
        } = this.state;
        const { heatMap } = this.props;
        let tempHeatMapInstance = null;
        if (heatMapInstance != null) {
            heatMapInstance.setMap(null);
        }
        if (!showHeatMap && heatMap && heatMap.positions && heatMap.positions.length > 0) {
            if (heatMapInstance == null) {
                let mapData = [];
                if (maps) {
                    mapData = _map(heatMap.positions, (position) => new maps.LatLng(position.lat, position.lng),
                        // return { data: new maps.LatLng(position.lat, position.lng), weight: position.weight }
                    );
                }
                tempHeatMapInstance = new maps.visualization.HeatmapLayer({
                    data: mapData,
                });
                tempHeatMapInstance.set('radius', 20);
                tempHeatMapInstance.setMap(map);
            }
        }
        this.setState({ showHeatMap: !showHeatMap, heatMapInstance: tempHeatMapInstance });
    };

    toggleVideoSearch = () => {
        const {
            showVideoSearch, drawingManager, map, maps, mapHasPolygons,
        } = this.state;
        const state = { showVideoSearch: !showVideoSearch };
        drawingManager.setMap(null);
        if (!showVideoSearch) {
            drawingManager.setMap(map);
        }
        this.setState(state);
    };

    togglePlayPause = () => {
        const { showPlayPause } = this.state;
        const {
            allowMultipleRangeSlider, onAutoPlay, onPause, onSliderChange, sliderMaxValue,
        } = this.props;
        if (showPlayPause) {
            // action to stop playing
            this.setState({ showPlayPause: false });
            if (this.playPauseInterval != null) {
                clearInterval(this.playPauseInterval);
                this.playPauseInterval = null;
            }
            onPause();
        } else {
            // action to start playing
            this.setState({ showPlayPause: true });
            this.playPauseInterval = setInterval(() => {
                const { sliderValue, sliderRangeMinValue, sliderRangeMaxValue } = this.state;
                if ((!allowMultipleRangeSlider && sliderValue < sliderMaxValue) || (allowMultipleRangeSlider && sliderValue < sliderRangeMaxValue)) {
                    // slider range change callback
                    this.handleRangeChangeValueByPlay(sliderValue, '', sliderRangeMinValue, sliderRangeMaxValue);
                    // Slider change call back
                    onSliderChange(sliderValue, '');
                    onAutoPlay(sliderValue);
                }
            }, 1000);
        }
    };

    toggleShowLive = () => {
        const { showLive } = this.state;

        this.setState({ showLive: !showLive });
    };

    enableOfflineMode = () => {
        const { enableOfflineMode } = this.props;

        this.setState({ showLive: false });

        enableOfflineMode();

    }

    toggleDrawGeoFenceControl = (override) => {
        if (override != undefined) {
            this.setState({ drawGeoFence: override });
        } else {
            const { drawGeoFence } = this.state;
            this.setState({ drawGeoFence: !drawGeoFence });
        }
        this.refreshMap();
    };

    /**
     * toggleInfoBox is a function that toggles the visibility of the infobox
     * @param {boolean} toggleTo - an optional parameter to set the visibility of the infobox, if not provided the function will toggle the current state
     */
    toggleInfoBox = (toggleTo) => {
        const { showInfoBox } = this.state;
        this.setState({ showInfoBox: !isUndefined(toggleTo) ? toggleTo : !showInfoBox });
        this.refreshMap();
    };

    toggleCenterMapInput = () => {
        const { showCenterMapInput } = this.state;
        this.setState({ showCenterMapInput: !showCenterMapInput });
    };

    toggleSatelliteMode = () => {
        const { showSatelliteMode, map, maps } = this.state;
        this.setState({ showSatelliteMode: !showSatelliteMode });
        if (map) {
            if (!showSatelliteMode) {
                map.setMapTypeId(maps.MapTypeId.SATELLITE);
            } else {
                map.setMapTypeId(maps.MapTypeId.ROADMAP);
            }
        }
    };

    sliderChangeValue = (value, formattedValue) => {
        this.setState({ sliderValue: value });
        const { onSliderChange } = this.props;
        onSliderChange(value, formattedValue);
    };

    handleRangeChangeValueByPlay = (value, formattedValue, RangeMin, RangeMax) => {
        this.setState({ sliderValue: value, sliderRangeMinValue: RangeMin, sliderRangeMaxValue: RangeMax });
        const { onSliderRangeChange, path: originalPath } = this.props;
        const { path } = this.state;
        onSliderRangeChange(value, formattedValue, RangeMin, RangeMax, true);
        // update path
        const newPath = this.calculateNewPath(originalPath, RangeMin, RangeMax);
        this.setState({ path: newPath }, () => { this.refreshMap(); });
        // this.refreshMap();
    };

    sliderRangeChangeValue = (value, formattedValue, RangeMin, RangeMax) => {
        this.setState({ sliderValue: value, sliderRangeMinValue: RangeMin, sliderRangeMaxValue: RangeMax });
        const { onSliderRangeChange, path: originalPath } = this.props;
        const { path } = this.state;
        onSliderRangeChange(value, formattedValue, RangeMin, RangeMax, false);

        // update path
        const newPath = this.calculateNewPath(originalPath, RangeMin, RangeMax);

        this.setState({ path: newPath }, () => {
            debounce(this.refreshMap, 300);
        });
    };

    calculateNewPath = (originalPath, RangeMin, RangeMax) => {
        const { sliderStep, sliderValueUnit } = this.props;

        const unit = sliderValueUnit == 'seconds' ? 's' : 'm';
        const date = (originalPath[0] && originalPath[0].time) ? dayjs(originalPath[0].time).startOf('day') : dayjs().startOf('day');
        const minDate = date.clone().add(RangeMin * sliderStep, unit).format('YYYY-MM-DD HH:mm:ss');
        const maxDate = date.clone().add(RangeMax * sliderStep, unit).format('YYYY-MM-DD HH:mm:ss');

        const newPath = originalPath.filter((path) => {
            const time = dayjs(path.time).format('YYYY-MM-DD HH:mm:ss');
            return time >= minDate && time <= maxDate;
        });
        return newPath;
    };

    onDateChanged = (date) => {
        const { assetPathLines } = this.state;
        const { onDateChange, selectedAsset } = this.props;

        if (assetPathLines.length > 0) {
            assetPathLines.forEach((assetPathLine) => {
                assetPathLine.setMap(null);
            });
        }

        const mapAssetDate = date ? date.format('DD/MM/YYYY') : dayjs().format('DD/MM/YYYY');
        let sliderValue;
        if (mapAssetDate == dayjs().format('DD/MM/YYYY')) {
            if (selectedAsset) sliderValue = dayjs().hour() * 3600;
            else sliderValue = dayjs().hour() * 60;
        } else {
            if (selectedAsset) sliderValue = 86399; // seconds in a day minus 1
            else sliderValue = 1439; // minutes in a day minus 1
        }
        this.setState({
            mapAssetDate,
            sliderValue,
            assetPathLines: [],
            assetPath: null,
            path: null,
            key: dayjs(),
        }, () => { this.refreshMap(); });
        onDateChange(mapAssetDate);
        this.executeAutoZoom();
    };

    onClick = ({
        x, y, lat, lng, event,
    }) => {
        const {
            geoFencePolyLines, drawGeoFence, map, maps, mapPolyLines, geoFencePolygon, polyLineMarkers,
        } = this.state;
        const { geFenceCompleted, noLimit } = this.props;
        // Check if point is within the polygon
        // if true then stop user from adding more points to polygon
        if (drawGeoFence) {
            let geoFenceCords = [];
            let status = 0;
            let xLat = lat;
            let xLng = lng;
            let modifyDrawGeoFence = drawGeoFence;
            const isFirstMarker = (geoFencePolyLines && geoFencePolyLines.length === 0);
            const firstCordinates = (geoFencePolyLines && geoFencePolyLines[0]) || null;
            geoFencePolyLines.push({ lat, lng });
            if (mapPolyLines) {
                mapPolyLines.setMap(null);
            }
            const markerIcon = {
                url: isFirstMarker ? redMarker : greenMarker,
            };

            if (firstCordinates) {
                // const distance = this.getDistanceInMiles({ lat: lat, lng: lng }, firstCordinates)
                if (geoFencePolyLines.length === 4 && !noLimit) {
                    modifyDrawGeoFence = false;
                    // modify last entry for poly lines as well
                    // geoFencePolyLines[geoFencePolyLines.length - 1] = geoFencePolyLines[0]
                    geoFencePolyLines.push(geoFencePolyLines[0]);
                    geoFenceCords = geoFencePolyLines;
                    xLat = geoFencePolyLines[geoFencePolyLines.length - 1].lat;
                    xLng = geoFencePolyLines[geoFencePolyLines.length - 1].lng;
                    status = 1;
                    const markerFinal = new maps.Marker({
                        position: { lat, lng },
                        icon: markerIcon,
                    });
                    markerFinal.setMap(map);
                    polyLineMarkers.push(markerFinal);
                }
            }

            let tempMapPolyLines = null;
            if (maps != null) {
                tempMapPolyLines = new maps.Polyline({
                    path: geoFencePolyLines,
                    geodesic: true,
                    strokeColor: '#fbad1d',
                    strokeOpacity: 1.0,
                    strokeWeight: 2,
                });
                tempMapPolyLines.setMap(map);

                const marker = new maps.Marker({
                    position: { lat: xLat, lng: xLng },
                    icon: markerIcon,
                });
                marker.setMap(map);
                polyLineMarkers.push(marker);
                // if this is the first
                if (polyLineMarkers.length === 1) {
                    marker.addListener('click', (event) => {
                        event.domEvent.stopImmediatePropagation();
                        event.domEvent.stopPropagation();
                        status = polyLineMarkers?.length > 2;
                        if (status) {
                            geoFencePolyLines.push(geoFencePolyLines[0]);
                            this.setState({
                                geoFencePolyLines,
                                drawGeoFence: false,
                            });
                            geFenceCompleted(status, geoFencePolyLines);
                        }
                    });
                }
            }

            this.setState({
                geoFencePolyLines,
                mapPolyLines: tempMapPolyLines,
                drawGeoFence: modifyDrawGeoFence,
                geoFenceCords,
                polyLineMarkers,
            });
            geFenceCompleted(status, geoFencePolyLines);
        }
    };

    getDistanceInMiles = (point1, point2) => {
        const R = 6371; // Radius of the earth in km
        const dLat = (point2.lat - point1.lat) * Math.PI / 180; // deg2rad below
        const dLon = (point2.lng - point1.lng) * Math.PI / 180;
        const a = 0.5 - Math.cos(dLat) / 2
      + Math.cos(point1.lat * Math.PI / 180) * Math.cos(point2.lat * Math.PI / 180)
      * (1 - Math.cos(dLon)) / 2;
        const distanceInKm = R * 2 * Math.asin(Math.sqrt(a));
        return distanceInKm / 1.609;
    };

    mapOptions = (maps) => {
        const { showSatelliteMode } = this.state;
        const { allowZoom, allowStreetView } = this.props;
        return {
            streetViewControl: allowStreetView,
            scaleControl: allowZoom,
            fullscreenControl: false,
            styles: [{
                featureType: 'poi.business',
                elementType: 'labels',
                stylers: [{
                    visibility: 'off',
                }],
            }],
            gestureHandling: 'greedy',
            disableDoubleClickZoom: !allowZoom,
            mapTypeControl: false,
            mapTypeId: showSatelliteMode ? maps.MapTypeId.SATELLITE : maps.MapTypeId.ROADMAP,
            mapTypeControlOptions: {
                style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
                position: maps.ControlPosition.BOTTOM_CENTER,
                mapTypeIds: [
                    maps.MapTypeId.ROADMAP,
                    maps.MapTypeId.SATELLITE,
                    maps.MapTypeId.HYBRID,
                ],
            },
            zoomControl: allowZoom,
            clickableIcons: false,
        };
    };

    containsObject = (obj, list) => {
        for (let i = 0; i < list.length; i++) {
            if (list[i] === obj) {
                return i;
            }
        }
        return false;
    };

    drawGeoFenceOnMap = () => {
        const { polygonBoundary, mapShapeOnClick } = this.props;
        const {
            maps, map, showGeoFence, geoFencePolygon,
        } = this.state;
        const newPolygons = [];
        if (polygonBoundary.length > 0 && showGeoFence && maps) {
            let foundKey = false;

            polygonBoundary.forEach((findFence, findFenceIndex) => {
                if (findFence.id == 'new') {
                    foundKey = findFenceIndex;
                }
            });
            if (geoFencePolygon[foundKey]) {
                geoFencePolygon[foundKey].setMap(null);
            }
            this.clearGeofence();
            polygonBoundary.forEach((geoFenceBox) => {
                const geoFence = new maps.Polygon({
                    paths: geoFenceBox && geoFenceBox.coordinates ? geoFenceBox.coordinates : [],
                    strokeColor: '#000',
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: '#000',
                    fillOpacity: 0.15,
                });
                geoFence.setMap(map);
                geoFence.addListener('click', (e) => mapShapeOnClick(e, geoFence, geoFenceBox.id ? geoFenceBox.id : false));
                newPolygons.push(geoFence);
            });
            this.setState({ geoFencePolygon: newPolygons });
        } else if (showGeoFence == false || (!polygonBoundary.length && geoFencePolygon.length)) {
            this.clearGeofence();
        }
    }

    clearGeofence = () => {
        this.state.geoFencePolygon.forEach((prevPolygon) => {
            prevPolygon.setMap(null);
        });
        this.setState({ geoFencePolygon: [] });
    }

    showGeoFenceOnMap = (show) => {
        this.setState({ showGeoFence: show });
        setTimeout(() => this.refreshMap(), 300);
    };

    refreshMap = () => {
        const {
            maps, map, geoFenceCords, geoFencePolygon, showHeatMap, heatMapInstance, path,
        } = this.state;
        const {
            mapShapeOnClick, heatMap, autoZoom, assets, sliderValue,
        } = this.props;
        if (this._googleMap) this._googleMap._setViewSize();
        const mapCenter = (map && map.getCenter()) || null;
        const center = mapCenter ? { lat: mapCenter.lat(), lng: mapCenter.lng() } : { lat: 0, lng: 0 };
        if (geoFenceCords && geoFenceCords.length > 0) {
            if (geoFencePolygon && geoFencePolygon.length > 0) {
                let foundKey = false;
                geoFencePolygon.forEach((findFence, findFenceIndex) => {
                    if (findFence.id == 'new') {
                        foundKey = findFenceIndex;
                    }
                });
                if (geoFencePolygon[foundKey]) {
                    geoFencePolygon[foundKey].setMap(null);
                }
            }
            const geoFence = new maps.Polygon({
                paths: geoFenceCords,
                id: 'new',
                strokeColor: '#fbad1d',
                strokeOpacity: 0.8,
                strokeWeight: 3,
                fillColor: '#fbad1d',
                fillOpacity: 0.35,
            });

            geoFence.setMap(map);
            geoFence.addListener('click', (e) => mapShapeOnClick(e, geoFence));
            geoFencePolygon.push(geoFence);
            this.setState({ geoFencePolygon });
        }
        let tempHeatMapInstance = null;
        if (heatMapInstance != null) {
            heatMapInstance.setMap(null);
        }
        if (showHeatMap && heatMap && heatMap.positions && heatMap.positions.length > 0) {
            let mapData = [];
            if (maps) {
                mapData = _map(heatMap.positions, (position) => new maps.LatLng(position.lat, position.lng),
                    // return { data: new maps.LatLng(position.lat, position.lng), weight: position.weight }
                );
            }
            tempHeatMapInstance = new maps.visualization.HeatmapLayer({
                data: mapData,
            });
            tempHeatMapInstance.set('radius', 20);
            tempHeatMapInstance.setMap(map);
            const latLngBounds = new maps.LatLngBounds();
            mapData.forEach((data) => {
                latLngBounds.extend(data);
            });
            map.fitBounds(latLngBounds);
        } else if (!showHeatMap && heatMapInstance != null) {
            heatMapInstance.setMap(null);
        }
        this.setState({ heatMapInstance: tempHeatMapInstance });
        if (maps && maps.event) {
            maps.event.trigger(map, 'resize');
        }
        this.showPathOnMap();
        this.drawGeoFenceOnMap();
    };

    /**
     * Autozoom to the currently enabled markers. These should match the assets checked in the assets sidebar.
     *
     * @param number forceZoomLevel  force a particular zoom level, null by default
     * @param number forceAssetId  id of an asset you want to force the zoom on, defaults to all assets
     */
    executeAutoZoom = (forceZoomLevel = null, forceAssetId = null) => {
        const {
            autoZoom, assets, sliderValue, markers, polygonBoundary, searchPolygonPath,
        } = this.props;
        const propsPath = (this.props && this.props.path) || [];
        const {
            maps, map, path, showGeoFence, mapAssetDate,
        } = this.state;

        if (autoZoom && maps && map) {
          const originalMaxZoom = map.maxZoom;
          const selectedAssets = filter(assets.map((asset) => {
              asset.arrowIcon = asset.arrowIcon || false;
              asset.className = asset.className || '';
              asset.currentMarker = asset.markers[parseInt(sliderValue, 10)] || null;
              if (asset.arrowIcon == true && asset.currentMarker && Number.isFinite(asset.currentMarker.angle)) {
                  asset.currentMarker.showArrowIcon = true;
              }
              return asset;
          }), (result) => {
            if (forceAssetId) {
                return (parseInt(result.asset_id) === forceAssetId);
            } else {
                return result.currentMarker;
            }
          });

            let bounds = [];
            if (isEmpty(path) && selectedAssets && selectedAssets.length > 0) {
                bounds = selectedAssets.map((asset) => new maps.LatLng(parseFloat(asset.currentMarker.lat), parseFloat(asset.currentMarker.lng)));
            } else if (path && path.length > 0) {
                bounds = path.map((dot) => new maps.LatLng(parseFloat(dot.lat), parseFloat(dot.lng)));
            } else if (searchPolygonPath && searchPolygonPath.length > 0) {
                bounds = searchPolygonPath.map((m) => new maps.LatLng(parseFloat(m.lat), parseFloat(m.lng)));
            } else if (markers && markers.length > 0) {
                if (markers.length == 1) {
                    const circleOptions = {
                        center: new maps.LatLng(parseFloat(markers[0].lat), parseFloat(markers[0].lng)),
                        fillOpacity: 0,
                        strokeOpacity: 0,
                        visible: false,
                        map,
                        radius: 242, /* 0.15 miles */
                    };
                    const circle = new maps.Circle(circleOptions);
                    if (forceZoomLevel) map.setOptions({ maxZoom: forceZoomLevel });
                    map.fitBounds(circle.getBounds());
                    if (forceZoomLevel) map.setOptions({ maxZoom: originalMaxZoom });
                } else {
                    const filteredMarkers = markers.filter((m) => m.lat && m.lng);
                    bounds = filteredMarkers.map((m) => new maps.LatLng(parseFloat(m.lat), parseFloat(m.lng)));
                }
            } else if (showGeoFence && polygonBoundary && polygonBoundary[0] && polygonBoundary[0].coordinates) {
                // If we're displaying a geoFence, generate the bounds for it
                bounds = polygonBoundary[0].coordinates.map((m) => new maps.LatLng(parseFloat(m.lat), parseFloat(m.lng)));
            } else if (propsPath && propsPath.length > 0) {
                bounds = propsPath.map((dot) => new maps.LatLng(parseFloat(dot.lat), parseFloat(dot.lng)));
            }

          if (bounds && bounds.length > 0) {
              const latLngBounds = new maps.LatLngBounds();
              bounds.forEach((bound) => {
                  latLngBounds.extend(bound);
              });
              if (forceZoomLevel) map.setOptions({ maxZoom: forceZoomLevel });
              map.fitBounds(latLngBounds);
              if (forceZoomLevel) map.setOptions({ maxZoom: originalMaxZoom });
              this.props.pathGeneratedCallback();
            }
        }
    };

    referenceSearchInput = (ref) => this.searchInput = ref;

    onPlacesChanged = () => {
        const { map } = this.state;
        const { onPlacesChanged } = this.props;
        const selected = this.searchBox.getPlaces();
        if (selected && selected.length > 0) {
            const { 0: place } = selected;
            if (!place.geometry) {
                return;
            }
            if (place.geometry.viewport) {
                map.fitBounds(place.geometry.viewport);
            } else {
                map.setCenter(place.geometry.location);
                map.setZoom(16);
            }

            // Set the input to the address
            this.setState({ searchInputValue: place.formatted_address });

            const center = {};
            center.lat = place.geometry.location.lat();
            center.lng = place.geometry.location.lng();
            this.setState({
                center,
                centerMapMarker: { lat: center.lat, lng: center.lng },
            });
            onPlacesChanged(center);
        }
    };


    changeCenter = (map) => {
        const center = {};
        center.lat = map.center.lat();
        center.lng = map.center.lng();
        this.setState({ center });
    };

    refreshCenter = (lat, lng) => {
        const center = {};
        center.lat = lat;
        center.lng = lng;
        this.setState({ center });
    };

    centerCurrentLocation = () => {
        let imgX = '0';
        const element = document.getElementById('current_location_img');
        const animationInterval = setInterval(() => {
            if (imgX == '-32') {
                imgX = '0';
            } else {
                imgX = '-32';
            }
            if (element.length > 0) {
                element.style.backgroundPosition = `${imgX}px 0px`;
            }
        }, 500);
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                clearInterval(animationInterval);
                const center = {};
                center.lat = position.coords.latitude;
                center.lng = position.coords.longitude;
                if (element.length > 0) {
                    element.style.backgroundPosition = '-144px 0px';
                }
                this.setState({ center, mapComponentKey: Math.random() });
            });
        } else {
            clearInterval(animationInterval);
        }
    };

    mapOnChange = ({
        center, zoom, bounds, marginBounds,
    }) => {
        // checking without this code
        // this.props.onChange({
        //     center, zoom, bounds, marginBounds,
        // });
        this.showPathOnMap();
    };

    snapPathToRoads = () => {
        const { path } = this.state;
        const { apiKey } = this.props;
        const pathString = (path.map((pathDot) => `${pathDot.lat},${pathDot.lng}`)).join('|');
        let url = `https://roads.googleapis.com/v1/snapToRoads?path=${pathString}&interpolate=true`;
        if (apiKey) {
            url += `&key=${apiKey}`;
        }
        const xmlHttp = new XMLHttpRequest();
        xmlHttp.open('GET', url, false);
        xmlHttp.send(null);
        const response = xmlHttp.responseText ? JSON.parse(xmlHttp.responseText) : {};
        return response && response.snappedPoints ? response.snappedPoints.map((snapPoint) => ({ lat: snapPoint.location.latitude, lng: snapPoint.location.longitude })) : [];
    };

    every_nth = (arr, nth) => arr.filter((e, i) => i % nth === nth - 1);

    getRandomColor = () => {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    };

    showPathOnMap = () => {
        const {
            showPathAsLine, assets, pathInfoOnClick, mapMode,
        } = this.props;
        const {
            path, showInfoBox, maps, map, assetPathLines,
        } = this.state;
        let mapPath = path;
        if (assetPathLines.length > 0) {
            assetPathLines.forEach((assetPathLine) => {
                assetPathLine.setMap(null);
            });
        }
        if (path && showPathAsLine) {
            mapPath = path.filter((dotFilter, dotFilterKey) => dotFilter.position != 'path');
        }
        let pathOnMap = null;
        if (mapPath && mapPath.length && mapPath.length > 0) {
            pathOnMap = mapPath.map((dot, dotIndex) => {
                if (dot.position === 'info') {
                    return (
                        <PathDot
                            key={dotIndex}
                            lat={dot.lat}
                            lng={dot.lng}
                            title={dot.title}
                            content={dot.content || 'XXXXXX'}
                            showInfoBox={showInfoBox} />
                    );
                }
                if (dot.position === 'event') {
                    return (
                        <PathInfo
                            key={dotIndex}
                            lat={dot.lat}
                            lng={dot.lng}
                            title={dot.title}
                            icon={dot.icon}
                            content={dot.content || 'XXXXXX'}
                            pathInfoOnClick={pathInfoOnClick}
                            data={dot} />
                    );
                }
                if (dot.position === 'marker') {
                    if (dot?.arrowIcon) {
                        return <></>;
                    } else {
                        return (
                            <Asset
                                key={dotIndex}
                                asset={dot}
                                markerOnClick={this.localMarkerOnClick}
                                markerOnMouseEnter={once(this.props.markerOnMouseEnter)}
                                markerOnMouseLeave={once(this.props.markerOnMouseLeave)}
                                markerPopover={dot.content ? this.props.markerPopover : null}
                                currentHoveredAsset={this.props.currentHoveredAsset}
                                lat={dot.lat}
                                lng={dot.lng}
                                showInfoBox={showInfoBox}
                                optional={{}}
                                isOnline={dot.currentMarker && parseInt(dot.currentMarker.device_status, 10) === 1}
                            />
                        );
                    }
                }

                if (mapMode === MAP_MODE_DOTS) {
                    return (
                        <RoadArrow
                            key={`customRoadArrow-${dotIndex}-${dot.lat}-${dot.lng}`}
                            asset={dot}
                            lat={dot.lat}
                            lng={dot.lng}
                            showInfoBox={showInfoBox} />
                    );
                }
                return (
                    <PathPosition
                        key={dotIndex}
                        title={dot.title}
                        content={dot.content}
                        text={dot.position}
                        lat={dot.lat}
                        lng={dot.lng}
                        showInfoBox={false} />
                );
            });

            // building path markers
            const usedCoordinates = [];
            let currentCoordinates = '';
            const aditionalPath = [];

            const zoom = (map && map.zoom) || 8;
            const nthElement = getSplitNumberByZoom(zoom);
            const minumumDistance = mapMode === MAP_MODE_DOTS ? 0 : getMinumumDistanceBetweenArrowsFromZoom(zoom);
            const splittedPath = this.every_nth(path, nthElement);

            let lastLat = null;
            let lastLng = null;

            splittedPath.map((dot, dotIndex) => {
                currentCoordinates = `${dot.lat}-${dot.lng}`;
                if (!usedCoordinates.includes(currentCoordinates)) {
                    let distance = 999;
                    if (lastLat && lastLng) {
                        distance = getDistanceFromLatLonInKm(lastLat, lastLng, dot.lat, dot.lng);
                    }

                    if (distance > minumumDistance) {
                        lastLat = dot.lat;
                        lastLng = dot.lng;
                        usedCoordinates.push(currentCoordinates);
                        aditionalPath.push(<RoadArrow
                            key={`customRoadArrow-${dotIndex}`}
                            asset={dot}
                            lat={dot.lat}
                            lng={dot.lng}
                            showInfoBox={showInfoBox} />);
                    }
                }
            });
            pathOnMap = pathOnMap.concat(aditionalPath);
        }

        const tempMapPolyLines = [];
        if (path && maps && mapMode === MAP_MODE_LINE) {
            const pathLine = path.map((pathDot) => ({
                lat: parseFloat(pathDot.lat),
                lng: parseFloat(pathDot.lng),
                seconds: pathDot.seconds ? parseInt(pathDot.seconds) : 0,
            }));
            // create separate path lines, if interval (in secs) between two path positions is greater or equal to 900
            const spiltPathLines = [];
            let previousTime = pathLine && pathLine.length > 0 ? pathLine[0].seconds : 0;
            const localSplitPathLine = [];
            pathLine.forEach((pathdot) => {
                const tempCheck = Math.abs(previousTime - pathdot.seconds);
                localSplitPathLine.push({ ...pathdot, diff: tempCheck });
                previousTime = pathdot.seconds;
            });
            if (localSplitPathLine) {
                spiltPathLines.push(localSplitPathLine);
            }
            const filterSplitLines = filter(spiltPathLines, (spiltPathLine) => spiltPathLine.length > 1);
            filterSplitLines.forEach((filterSpiltPathLine, filterSpiltPathLineIndex) => {
                const localMapPolyLines = new maps.Polyline({
                    path: filterSpiltPathLine,
                    geodesic: true,
                    strokeColor: '#1890ff', // this.getRandomColor(),
                    strokeOpacity: 2.0,
                    strokeWeight: 5,
                });
                localMapPolyLines.setMap(map);
                tempMapPolyLines.push(localMapPolyLines);
            });
        }
        this.setState({ assetPath: pathOnMap, assetPathLines: tempMapPolyLines });
    };

    localMarkerOnClick = (marker) => {
        const { markerOnClick } = this.props;
        const { assetPathLines } = this.state;
        if (assetPathLines.length > 0) {
            assetPathLines.forEach((assetPathLine) => {
                assetPathLine.setMap(null);
            });
        }
        new Promise((resolve, reject) => {
            resolve(markerOnClick(marker));
        })
            .then((response) => {
                this.showPathOnMap();
            })
            .then((response) => {
                setTimeout(() => this.executeAutoZoom(), 1000);
            });
    };

    getMapSearchShape = (e) => {
        const { getMapSearchBoundary } = this.props;
        const { drawingShape } = this.state;
        const path = drawingShape[0].getPath();
        const polygonPath = [];
        for (let i = 0; i < path.getLength(); i += 1) {
            const point = path.getAt(i);
            polygonPath.push({ lat: point.lat(), lng: point.lng() });
        }
        getMapSearchBoundary(polygonPath);
    };

    clearMapSearchInput = () => {
        this.setState({ searchInputValue: '' });
        if (this.searchInput) {
            this.searchInput.focus();
        }
    }

    getStep = (sliderMinValue, sliderMaxValue) => {
        let step = ((sliderMaxValue - sliderMinValue) * 1000) / 100; // 100 steps
        const { mapToolBarButtons } = this.props;
        const playPauseButton = mapToolBarButtons.find((obj) => obj && obj.key === 'play-pause');
        if (playPauseButton && playPauseButton.visible === true) {
            step = 1000; // 1 second
        }
        return step;
    };

    handleIncreaseOptionCallback = (option) => {
        const { increaseOptionCallback } = this.props;
        this.setState({ selectedIncreaseOption: option });
        increaseOptionCallback(option);
    };

    getToolbarContent = (
        calculatedSliderValue,
        sliderCalculatedMaxValue,
        selectedMapToolBarButtons,
        calculatedMapAssetDate,
        step,
    ) => {
        const {
            showToolbar, highlightSlider, sliderStep, hideSlider, hideDatePicker,
            sliderMinValue, sliderRangeMinValue, sliderRangeMaxValue, sliderValueUnit,
            onDateChange, allowMultipleRangeSlider, forwardCallback, rewindCallback,
            showResetBtn, resetCallback, toolbarLoading, markers, assets, showNoAssetSelectedWarning,
            selectedAsset, selectedDeviceTimeline, selectedDeviceGeofenceTimeline, showTimingPresetsFooter,
        } = this.props;
        const { showLive, showCenterMapInput, selectedIncreaseOption } = this.state;
        if (showToolbar) {
            if (!toolbarLoading) {
                return (
                    <Toolbar
                        isLive={showLive}
                        markers={markers}
                        assets={assets}
                        highlightSlider={highlightSlider}
                        sliderStep={sliderStep}
                        hideSlider={hideSlider}
                        hideDatePicker={hideDatePicker}
                        sliderMinValue={sliderMinValue}
                        sliderValue={calculatedSliderValue}
                        sliderMaxValue={sliderCalculatedMaxValue}
                        sliderRangeMinValue={sliderRangeMinValue}
                        sliderRangeMaxValue={sliderRangeMaxValue}
                        sliderValueUnit={sliderValueUnit}
                        onDateChanged={this.onDateChanged}
                        sliderChangeValue={this.sliderChangeValue}
                        sliderRangeChangeValue={this.sliderRangeChangeValue}
                        onDateChange={onDateChange}
                        referenceSearchInput={this.referenceSearchInput}
                        searchInputValue={this.state.searchInputValue}
                        onSearchInputChange={(e) => this.setState({ searchInputValue: e.target.value })}
                        handleAddressSearchClear={this.clearMapSearchInput}
                        buttons={selectedMapToolBarButtons}
                        mapAssetDate={calculatedMapAssetDate}
                        showCenterMapInput={showCenterMapInput}
                        allowMultipleRangeSlider={allowMultipleRangeSlider}
                        step={step}
                        forwardCallback={forwardCallback}
                        rewindCallback={rewindCallback}
                        timelineIncreaseOptions={this.timelineIncreaseOptions}
                        selectedIncreaseOption={selectedIncreaseOption}
                        increaseOptionCallback={this.handleIncreaseOptionCallback}
                        showResetBtn={showResetBtn}
                        resetCallback={resetCallback}
                        showNoAssetSelectedWarning={showNoAssetSelectedWarning}
                        selectedAsset={selectedAsset}
                        selectedDeviceTimeline={selectedDeviceTimeline}
                        selectedDeviceGeofenceTimeline={selectedDeviceGeofenceTimeline}
                        enableOfflineMode={this.enableOfflineMode}
                        showTimingPresetsFooter={showTimingPresetsFooter}
                    />
                );
            }
            return (
                <div style={{ height: '145px' }} />
            );
        } return null;
    };

    render() {
        const {
            assets,
            heatMap,
            mapToolBarButtons,
            apiKey,
            onDateChange,
            markerOnClick,
            pathInfoOnClick,
            children,
            markers,
            showToolbar,
            showFindCenterButton,
            hideSlider,
            mapShapeOnClick,
            polygonBoundary,
            sliderStep,
            zoom,
            autoZoom,
            showPathAsLine,
            snapToRoads,
            hideDatePicker,
            sliderMinValue,
            sliderMaxValue,
            sliderValueUnit,
            searchPolygonPath,
            allowMultipleRangeSlider,
            getMapSearchBoundary,
            highlightSlider,
            forwardCallback,
            rewindCallback,
            increaseOptionCallback,
            showResetBtn,
            resetCallback,
            selectedAsset,
            timesheetReport,
            mapMode,
            onTilesLoaded,
            sidebarIsVisible,
        } = this.props;
        const {
            path,
            showTraffic,
            showHeatMap,
            showVideoSearch,
            drawGeoFence,
            geoFenceCords,
            mapComponentKey,
            showInfoBox,
            showCenterMapInput,
            mapAssetDate,
            showSatelliteMode,
            center,
            sliderValue,
            assetPathLines,
            assetPath,
            showLive,
            mapHasPolygons,
            sliderRangeMinValue,
            sliderRangeMaxValue,
            showPlayPause,
            centerMapMarker,
            geoFencePolygon,
            showGeoFence,
        } = this.state;

        const actualSliderValue = sliderValue > 8639 && window.location.href.includes('map') ? Math.round(sliderValue / 10) : sliderValue;
        const selectedAssets = filter(assets.map((asset) => {
            asset.arrowIcon = asset.arrowIcon || false;
            asset.className = asset.className || '';
            asset.currentMarker = asset.markers.filter((marker) => marker.time_interval == actualSliderValue)[0] || null;
            if (asset.arrowIcon == true && asset.currentMarker && Number.isFinite(asset.currentMarker.angle)) {
                asset.currentMarker.showArrowIcon = true;
            }
            asset.is_idle = asset.is_idle || false;
            return asset;
        }), (result) => result.currentMarker);
        const mapLayers = [];

        if (showTraffic) {
            mapLayers.push('TrafficLayer');
        }
        const heatMapData = showHeatMap ? heatMap : {};

        const liveIcon = (
            <div style={{ width: 55 }}>
                <span>
                    <svg height={12} width={12} className="blinking">
                        <circle cx={6} cy={6} r={3} fill="#FF0000" />
                    </svg>
                </span>
                <span style={{ marginLeft: '5px' }}>
                    Live
                </span>
            </div>
        );

        let sliderCalculatedMaxValue = sliderMaxValue;
        let calculatedSliderValue = sliderValue;
        let calculatedMapAssetDate = mapAssetDate;

        if (showLive) {
            calculatedSliderValue = sliderMaxValue;
            calculatedMapAssetDate = dayjs().format('DD/MM/YYYY');
        }

        if (calculatedMapAssetDate == dayjs().format('DD/MM/YYYY')) {
            let currentTimeInUnits;
            if (!selectedAsset) currentTimeInUnits = (parseInt(dayjs().format('H')) * 60) + parseInt(dayjs().format('m'));
            else currentTimeInUnits = (parseInt(dayjs().format('H')) * 3600) + (parseInt(dayjs().format('m')) * 60) + parseInt(dayjs().format('s'));
            sliderCalculatedMaxValue = Math.floor(currentTimeInUnits / sliderStep);
        }
        const step = this.getStep(sliderMinValue, sliderCalculatedMaxValue);

        let tempShowVideoSearch = showVideoSearch;
        if (searchPolygonPath.length > 0) {
            tempShowVideoSearch = true;
        }

        let assetListIcon = 'hamb-with-dots';

        const defaultMapToolBarButtons = [
            {
                key: 'side-bar', onClick: () => { }, icon: assetListIcon, tooltip: sidebarIsVisible ? 'Hide Sidebar' : 'Show Sidebar', className: '', selected: sidebarIsVisible, visible: true,
            },
            {
                key: 'info-box', onClick: () => { this.toggleInfoBox(); }, icon: 'info', tooltip: showInfoBox ? 'Hide Asset Names' : 'Show Asset Names', className: '', selected: showInfoBox, visible: true,
            },
            {
                key: 'traffic', onClick: this.toggleTrafficLayer, icon: 'car', tooltip: showTraffic ? 'Hide Traffic Overlay' : 'Show Traffic Overlay', className: '', selected: showTraffic, visible: true,
            },
            {
                key: 'geo-fence', onClick: this.toggleDrawGeoFenceControl, icon: 'earth', tooltip: 'Geo-fences', className: '', selected: showGeoFence, visible: true,
            },
            {
                key: 'center-map', onClick: this.toggleCenterMapInput, icon: 'target', tooltip: showCenterMapInput ? 'Hide Map Search' : 'Show Map Search', className: '', selected: showCenterMapInput, visible: true,
            },
            {
                key: 'satellite', onClick: this.toggleSatelliteMode, icon: 'satellite', tooltip: showSatelliteMode ? 'Disable Satellite Mode' : 'Enable Satellite Mode', className: '', selected: showSatelliteMode, visible: true,
            },
            {
                key: 'heat-map', onClick: this.toggleHeatMapLayer, icon: 'fire', tooltip: 'Heatmaps', className: '', selected: showHeatMap, visible: true,
            },
            {
                key: 'video-search', onClick: this.toggleVideoSearch, icon: 'video', tooltip: tempShowVideoSearch ? 'Hide Video Search' : 'Show Video Search', className: '', selected: tempShowVideoSearch, visible: true,
            },
            {
                key: 'play-pause', onClick: () => { this.togglePlayPause(); }, icon: (showPlayPause ? 'pause' : 'play'), tooltip: (showPlayPause ? 'Pause Event' : 'Play Event'), className: '', selected: showPlayPause, visible: false,
            },
            {
                key: 'live-map', onClick: this.toggleShowLive, icon: 'clock', tooltip: (showLive ? 'Show Historic' : 'Show Live'), className: '', selected: showLive, visible: true, tag: showLive ? liveIcon : null,
            },
        ];

        // merge defaultMapToolBarButtons with the props.mapToolBarButtons
        // remove any which should not be visible
        const selectedMapToolBarButtons = defaultMapToolBarButtons
            .map((defaultObject) => {
                const newObject = mapToolBarButtons.find((obj) => obj && defaultObject.key === obj.key);
                return Object.assign(defaultObject, newObject);
            })
            .filter(({ visible }) => visible);

        const bootstrapURLKeys = {
            libraries: ['visualization', 'places', 'drawing'],
        };
        if (apiKey) {
            bootstrapURLKeys.key = apiKey;
        }

        return (
            <React.Fragment key={this.state.key}>
                {children}
                <div className="scorch-map updated-version">
                    {this.getToolbarContent(
                        calculatedSliderValue,
                        sliderCalculatedMaxValue,
                        selectedMapToolBarButtons,
                        calculatedMapAssetDate,
                        step,
                    )}
                    <GoogleMapReact
                        key={mapComponentKey}
                        options={this.mapOptions}
                        yesIWantToUseGoogleMapApiInternals
                        ref={(el) => {
                            this._googleMap = el;
                        }}
                        bootstrapURLKeys={bootstrapURLKeys}
                        onClick={this.onClick}
                        layerTypes={mapLayers}
                        hoverDistance={20}
                        heatmapLibrary={showHeatMap}
                        onChange={this.mapOnChange}
                        onTilesLoaded={() => { if (onTilesLoaded) onTilesLoaded(); }}
                        onGoogleApiLoaded={({ map, maps }) => {
                            /* ################# */
                            const _that = this;
                            const { drawingManager } = this.state;
                            const state = { map, maps, drawingManager };
                            if (drawingManager == null && maps.drawing) {
                                state.drawingManager = new maps.drawing.DrawingManager({
                                    drawingMode: maps.drawing.OverlayType.POLYGON,
                                    drawingControl: false,
                                    drawingControlOptions: {
                                        position: maps.ControlPosition.TOP_CENTER,
                                        drawingModes: [
                                            maps.drawing.OverlayType.POLYGON,
                                        ],
                                    },
                                    polygonOptions: {
                                        draggable: true,
                                        editable: true,
                                        fillColor: '#FFFFFF',
                                        fillOpacity: 0.5,
                                        strokeWeight: 2,
                                        zIndex: 99999,
                                    },
                                    circleOptions: {
                                        fillColor: '#FFFFFF',
                                        fillOpacity: 1,
                                        strokeWeight: 2,
                                        clickable: false,
                                        editable: true,
                                        zIndex: 1,
                                    },
                                });
                                maps.event.addListener(state.drawingManager, 'overlaycomplete', (e) => {
                                    const drawingShape = [];
                                    if (e.type != maps.drawing.OverlayType.MARKER) {
                                        const tempDrawingShape = e.overlay;
                                        tempDrawingShape.type = e.type;
                                        drawingShape.push(tempDrawingShape);
                                    }
                                    _that.setState({ mapHasPolygons: true, drawingShape });
                                    state.drawingManager.setDrawingMode(null);
                                    state.drawingManager.setOptions({
                                        drawingControlOptions: {
                                            position: maps.ControlPosition.TOP_CENTER,
                                            drawingModes: [
                                                maps.drawing.OverlayType.POLYGON,
                                            ],
                                        },
                                    });
                                });

                                if (searchPolygonPath.length > 0) {
                                    const searchPolygon = new maps.Polygon({
                                        paths: searchPolygonPath,
                                        draggable: true,
                                        editable: true,
                                        fillColor: '#FFFFFF',
                                        fillOpacity: 0.5,
                                        strokeWeight: 2,
                                    });
                                    searchPolygon.setMap(map);
                                    state.mapHasPolygons = true;
                                    state.drawingShape = [searchPolygon];
                                    state.drawingManager.setDrawingMode(null);
                                    state.drawingManager.setOptions({
                                        drawingControl: false,
                                        drawingControlOptions: {
                                            position: maps.ControlPosition.TOP_CENTER,
                                            drawingModes: [
                                                maps.drawing.OverlayType.POLYGON,
                                            ],
                                        },
                                    });
                                }
                            }
                            /* ################# */

                            this.setState(state, () => {
                                this.props.onMapLoaded();
                            });
                            if (tempShowVideoSearch) {
                                this.toggleVideoSearch();
                            }
                            this.drawGeoFenceOnMap();
                            if (showToolbar) {
                                if (maps.places) {
                                    this.searchBox = new maps.places.SearchBox(document.getElementById('map-search-box'));
                                    this.searchBox.addListener('places_changed', this.onPlacesChanged);
                                    this.searchBox.bindTo('bounds', map);
                                }
                            }
                            if (showFindCenterButton) {
                                currentButton(map, maps, this.centerCurrentLocation);
                            }

                            if (!zoom) {
                                this.executeAutoZoom();
                            }
                        }}
                        center={center}
                        onDragEnd={this.changeCenter}
                        defaultZoom={zoom}
                    >
                        {assetPath}
                        {selectedAssets
                            ? selectedAssets.map((asset, assetIndex) => {
                                let assetOnClick = this.localMarkerOnClick;
                                if (asset.onClick != 'undefined' && asset.onClick === false) {
                                    assetOnClick = () => { };
                                }

                                const optional = {};
                                if (asset.hasOwnProperty('visible') && asset.visible !== null) {
                                    optional.visible = asset.visible;
                                }

                                return (
                                    <Asset
                                        key={assetIndex}
                                        asset={asset}
                                        maps={this.state.maps}
                                        markerOnClick={assetOnClick}
                                        markerOnMouseEnter={this.props.markerOnMouseEnter}
                                        markerOnMouseLeave={this.props.markerOnMouseLeave}
                                        markerPopover={this.props.markerPopover}
                                        currentHoveredAsset={this.props.currentHoveredAsset}
                                        lat={asset.currentMarker.lat}
                                        lng={asset.currentMarker.lng}
                                        showInfoBox={showInfoBox}
                                        optional={optional}
                                        isOnline={asset.currentMarker.device_status === '1'}
                                        isIdle={asset.currentMarker.is_idle} />
                                );
                            })
                            : null}
                        {markers
                            ? markers.map((marker, markerIndex) => {
                                let onClick = this.localMarkerOnClick;
                                if (marker.onClick != 'undefined' && marker.onClick === false) {
                                    onClick = () => { };
                                } else if (marker.onClick != 'undefined' && marker.onClick !== false) {
                                    onClick = (m) => marker.onClick(m);
                                }

                                const optional = {};
                                if (marker.hasOwnProperty('visible') && marker.visible !== null) {
                                    optional.visible = marker.visible;
                                }

                                return (
                                    <Marker
                                        key={markerIndex}
                                        marker={marker}
                                        maps={this.state.maps}
                                        markerOnClick={onClick}
                                        markerOnMouseEnter={this.props.markerOnMouseEnter}
                                        markerOnMouseLeave={this.props.markerOnMouseLeave}
                                        markerPopover={this.props.markerPopover}
                                        currentHoveredAsset={this.props.currentHoveredAsset}
                                        lat={marker.lat}
                                        lng={marker.lng}
                                        showInfoBox={showInfoBox}
                                        live={showLive}
                                        optional={optional}
                                        isOnline={marker.device_status == '1'}
                                        isIdle={marker.is_idle} />
                                );
                            })
                            : null}
                            {centerMapMarker && (
                                <CenterMapMarker
                                    key="centerMapMarker"
                                    lat={centerMapMarker.lat}
                                    lng={centerMapMarker.lng}
                                />
                            )}
                            {
                                showGeoFence && 
                                zoom < SHOW_GEOFENCE_MARKERS_THRESHOLD && 
                                polygonBoundary?.length && 
                                polygonBoundary.map(geofence => {
                                    const averageLat = (geofence.coordinates
                                        .map(c => c.lat)
                                        .reduce((acc, lat) => acc + lat, 0))
                                        / geofence.coordinates.length;
                                    const averageLng = (geofence.coordinates
                                        .map(c => c.lng)
                                        .reduce((acc, lng) => acc + lng, 0))
                                        / geofence.coordinates.length;
                                    return (
                                        <GeofenceMapMarker
                                            key={geofence.id}
                                            lat={averageLat}
                                            lng={averageLng}
                                        />
                                    );
                                })
                            }
                    </GoogleMapReact>
                    {tempShowVideoSearch
                        ? (
                            <div>
                                <div className="scorch-map-drawing-toolbar">
                                    <Button.Group>
                                        <Tooltip 
                                            placement="bottom" 
                                            title="Draw Search Area"
                                        >
                                            <Button
                                                size="small"
                                                icon={<GatewayOutlined />}
                                                disabled={mapHasPolygons}
                                                onClick={() => {
                                                    const { drawingManager, maps, map } = this.state;
                                                    drawingManager.setDrawingMode(maps.drawing.OverlayType.POLYGON);
                                                    drawingManager.setMap(map);
                                                }} />
                                        </Tooltip>
                                        <Tooltip 
                                            placement="bottom" 
                                            title="Clear Drawn Area"
                                        >
                                            <Button
                                                size="small"
                                                icon={<DeleteOutlined />}
                                                onClick={() => {
                                                    const { drawingManager, maps, drawingShape } = this.state;
                                                    drawingManager.setDrawingMode(maps.drawing.OverlayType.POLYGON);
                                                    drawingManager.setOptions({
                                                        drawingControlOptions: {
                                                            position: maps.ControlPosition.TOP_CENTER,
                                                            drawingModes: [
                                                                maps.drawing.OverlayType.POLYGON,
                                                            ],
                                                        },
                                                    });
                                                    drawingShape.forEach((shape) => shape.setMap(null));
                                                    this.setState({ mapHasPolygons: false, drawingShape: [] });
                                                    getMapSearchBoundary([]);
                                                }}
                                                disabled={!mapHasPolygons} />
                                        </Tooltip>
                                        <Tooltip 
                                            placement="bottom" 
                                            title="Search Area for Videos"
                                        >
                                            <Button
                                                size="small"
                                                icon={<SearchOutlined />}
                                                onClick={this.getMapSearchShape}
                                                disabled={!mapHasPolygons}
                                                className='search-video-btn'
                                            >
                                                <span>Search Area</span>
                                            </Button>
                                        </Tooltip>
                                        <Tooltip placement="bottom" title="Help">
                                            <Popover
                                                placement="bottomRight"
                                                title="Video search instructions"
                                                content={(
                                                    <ul>
                                                        <li>Left click on the map to mark the boundaries of the area you would like to search</li>
                                                        <li>To complete the search area, click back on the first marker</li>
                                                        <li>Click ‘Search Videos’ to see the available video files that recorded in the selected area</li>
                                                    </ul>
                                                )}
                                                trigger="click">
                                                <Button size="small" icon={<QuestionOutlined />} />
                                            </Popover>
                                        </Tooltip>
                                    </Button.Group>
                                </div>
                            </div>
                        )
                        : null}
                </div>
            </React.Fragment>
        );
    }
}

Map.propTypes = {
    apiKey: PropTypes.string,
    center: PropTypes.object,
    zoom: PropTypes.number,
    markers: PropTypes.array,
    path: PropTypes.array,
    assets: PropTypes.array,
    currentHoveredAsset: PropTypes.string,
    heatMap: PropTypes.object,
    mapToolBarButtons: PropTypes.array,
    showSidebarButton: PropTypes.bool,
    showInfoButton: PropTypes.bool,
    showTrafficButton: PropTypes.bool,
    showGeoFenceButton: PropTypes.bool,
    showLocationButton: PropTypes.bool,
    showSatelliteButton: PropTypes.bool,
    showHeatMapButton: PropTypes.bool,
    onDateChange: PropTypes.func,
    markerOnClick: PropTypes.func,
    markerOnMouseEnter: PropTypes.func,
    markerOnMouseLeave: PropTypes.func,
    pathInfoOnClick: PropTypes.func,
    onChange: PropTypes.func,
    showToolbar: PropTypes.bool,
    toolbarLoading: PropTypes.bool,
    allowZoom: PropTypes.bool,
    allowStreetView: PropTypes.bool,
    showFindCenterButton: PropTypes.bool,
    refreshCenter: PropTypes.func,
    getGoogleMapApi: PropTypes.func,
    hideSlider: PropTypes.bool,
    mapShapeOnClick: PropTypes.func,
    polygonBoundary: PropTypes.array,
    sliderMaxValue: PropTypes.number,
    onSliderChange: PropTypes.func,
    onSliderRangeChange: PropTypes.func,
    sliderValue: PropTypes.number,
    mapDate: PropTypes.string,
    onPlacesChanged: PropTypes.func,
    onMounted: PropTypes.func,
    sliderStep: PropTypes.number,
    geFenceCompleted: PropTypes.func,
    showPathAsLine: PropTypes.bool,
    snapToRoads: PropTypes.bool,
    autoZoom: PropTypes.bool,
    hideDatePicker: PropTypes.bool,
    sliderMinValue: PropTypes.number,
    sliderValueUnit: PropTypes.string,
    getMapSearchBoundary: PropTypes.func,
    searchPolygonPath: PropTypes.array,
    allowMultipleRangeSlider: PropTypes.bool,
    sliderRangeMinValue: PropTypes.number,
    sliderRangeMaxValue: PropTypes.number,
    onAutoPlay: PropTypes.func,
    onPause: PropTypes.func,
    highlightSlider: PropTypes.array,
    forwardCallback: PropTypes.func,
    rewindCallback: PropTypes.func,
    increaseOptionCallback: PropTypes.func,
    showResetBtn: PropTypes.bool,
    resetCallback: PropTypes.func,
    selectedDeviceTimeline: PropTypes.object,
    selectedDeviceGeofenceTimeline: PropTypes.object,
    onMapLoaded: PropTypes.func,
    mapMode: PropTypes.string,
    enableOfflineMode: PropTypes.func,
    onTilesLoaded: PropTypes.func,
};

Map.defaultProps = {
    apiKey: '',
    center: {
        lat: 52.48759,
        lng: -1.91199,
    },
    zoom: 8,
    markers: [],
    path: [],
    assets: [],
    currentHoveredAsset: null,
    heatMap: {},
    mapToolBarButtons: [],
    showSidebarButton: true,
    showInfoButton: true,
    showTrafficButton: true,
    showGeoFenceButton: true,
    showLocationButton: true,
    showSatelliteButton: true,
    showHeatMapButton: true,
    onDateChange: () => { },
    markerOnClick: () => { },
    markerOnMouseEnter: () => { },
    markerOnMouseLeave: () => { },
    pathInfoOnClick: () => { },
    onChange: () => { },
    showToolbar: true,
    toolbarLoading: false,
    allowZoom: true,
    allowStreetView: true,
    showFindCenterButton: true,
    getGoogleMapApi: () => { },
    hideSlider: false,
    mapShapeOnClick: () => { },
    polygonBoundary: [],
    sliderMaxValue: 95,
    onSliderChange: (value) => { },
    onSliderRangeChange: (value) => { },
    sliderValue: 0,
    mapDate: dayjs().format('DD/MM/YYYY'),
    onPlacesChanged: () => { },
    onMounted: () => { },
    sliderStep: 5,
    geFenceCompleted: (status, points) => { },
    showPathAsLine: true,
    snapToRoads: false,
    autoZoom: true,
    hideDatePicker: false,
    sliderMinValue: 0,
    sliderValueUnit: 'minutes',
    getMapSearchBoundary: (polygon) => { },
    searchPolygonPath: [],
    allowMultipleRangeSlider: false,
    sliderRangeMinValue: 0,
    sliderRangeMaxValue: 95,
    onAutoPlay: (sliderValue) => { },
    onPause: () => { },
    highlightSlider: [],
    forwardCallback: () => { },
    rewindCallback: () => { },
    increaseOptionCallback: () => { },
    showResetBtn: false,
    resetCallback: () => { },
    selectedDeviceTimeline: [ ],
    selectedDeviceGeofenceTimeline: [ ],
    onMapLoaded: () => { },
    mapMode: MAP_MODE_LINE,
    enableOfflineMode: () => { },
    onTilesLoaded: () => { },
    pathGeneratedCallback: () => { },
};

export default Map;
