/* eslint-disable react/destructuring-assignment */
import { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { isUndefined, isNull } from 'underscore';

import moment from 'moment';
import DOM from './speedingReport';

import * as eventsActions from '../../../../core/events/eventsActions';
import * as reportActions from '../../../../core/report/reportActions';
import * as assetActions from '../../../../core/asset/assetActions';
import * as globalActions from '../../../../core/global/globalActions';

class SpeedingReport extends Component {
    constructor(props) {
        super(props);

        this.state = {
            filters: {},
            filterSidebar: true,
            export: false,
            reportHasBeenFetched: false,
            metricUnit: false,
        };
        this.view = DOM;
    }

    componentDidMount() {
        const { actions, dataEventToReportParams, profile } = this.props;
        actions.resetReportsRequest();

        if (dataEventToReportParams !== null) {
            const params = {
                ...dataEventToReportParams,
                page: 1,
            };

            if (dataEventToReportParams.asset_id) {
                params.assetId = [dataEventToReportParams.asset_id];
                params.asset_id = [dataEventToReportParams.asset_id];
            }

            actions.getSpeedingReportRequest(params);
            actions.setDataEventToReportParams(null);

            this.setState({ filters: params, filterSidebar: false });
        } else {
            this.onEventsTabFilterChange({
                asset_or_driver: 'assets',
                page: 1,
                date_from: moment().subtract(1, 'day'),
                date_to: moment(),
            });
        }

        const { unit_of_measurement_preference } = profile;

        if (unit_of_measurement_preference != 'british-imperial') {
            this.setState({ metricUnit: true });
        }
    }

    setUnit = (e) => {
        this.setState({ metricUnit: e?.key === 'metric' });
    };

    onEventsTabFilterChange = (values) => {
        const filters = values;
        if (filters.date_from) {
            filters.date_from = values.date_from.format('YYYY-MM-DD');
        }
        if (filters.date_to) {
            filters.date_to = values.date_to.format('YYYY-MM-DD');
        }

        if (!isNull(filters.asset_id) && !isUndefined(filters.asset_id)) {
            filters.assetId = filters.asset_id;
        }
        filters.page = 1;
        // delete  filters.asset_id;
        this.setState({ filters });
        const { actions } = this.props;
        const params = { ...filters };
        actions.getSpeedingReportRequest(params);

        this.setState({ reportHasBeenFetched: true });
    };

    onExportCSV = () => {
        if (this.props.isExportingSpeedingReportCsv) {
            return false;
        }
        const { actions } = this.props;
        const { filters, metricUnit } = this.state;
        filters.page = 1;
        filters.metric_unit = metricUnit ? 1 : 0;
        const params = { ...filters };

        actions.getSpeedingReportCsvRequest(params);
    };

    paginationChange = (page) => {
        const {
            actions, asset, searchParams, orderParams, pagination,
        } = this.props;
        const { filters } = this.state;

        if (page !== pagination.currentPage) {
            filters.page = page;
            delete filters.export;
            const params = { ...filters };
            actions.getSpeedingReportRequest(params);
        }
    };

    exportCsv() {
        const { speedingReport } = this.props;
        const { filters } = this.state;

        if (filters.date_from) {
            filters.date_from = filters.date_from;
        }
        if (filters.date_to) {
            filters.date_to = filters.date_to;
        }

        if (!isNull(filters.assetId) && !isUndefined(filters.assetId)) {
            filters.assetId = filters.asset_id;
        }
        filters.page = 1;
        // delete  filters.asset_id;
        filters.export = true;
        this.setState({ filters });
        const { actions } = this.props;
        const params = { ...filters };

        actions.getSpeedingReportRequest(params);
    }

    render() {
        return this.view(
            {
                update: (newVals) => (
                    this.props.actions.UpdateEventNotifications(newVals)
                ),
            },
        );
    }
}

SpeedingReport.propTypes = {
    actions: PropTypes.object.isRequired,
    speedingReport: PropTypes.array.isRequired,
    pagination: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        speedingReport: state.report.speedingReport,
        speedingReportBack: state.report.speedingReportBack,
        events: state.events.companyEvents,
        user: state.user,
        pagination: state.report.speedingReportPagination,
        export: state.export,
        filterSidebar: state.filterSidebar,
        dataEventToReportParams: state.global.dataEventToReportParams,
        profile: state.user.profile,
        isExportingSpeedingReportCsv: state.report.isExportingSpeedingReportCsv,
        isFetching: state.report.isFetching,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...eventsActions,
            ...reportActions,
            ...assetActions,
            ...globalActions,
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SpeedingReport);
