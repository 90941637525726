/* global window */
/* eslint-disable react/no-this-in-sfc */

import React from 'react';
import { Button, message } from 'antd';
import moment from 'moment';

import { useHistory } from 'react-router-dom';
import { isEmpty } from 'underscore';
import Icon from '../../../components/elements/Icon';
import VideoPayer from '../../../components/elements/VideoPlayer';

import './HistoricVideoScreen.scss';
import './HistoricVideoStates/Stream.css';
import NotFound from './HistoricVideoStates/NotFound';

function getVideo(video_url, cacheCallback, videoFailed = false, latestDownloadQue = {}, videos_loading) {
    if (video_url && video_url.endsWith('.mp4')) {
        return (
            // eslint-disable-next-line jsx-a11y/media-has-caption
            <video controls autoPlay width={768} height={432}>
                <source src={video_url} type="video/mp4" />
            </video>
        );
    }
    if (!videoFailed) {
        return <VideoPayer identity={1} url={video_url} videoType="flv" fullScreen />;
    }
    return <NotFound cacheCallback={cacheCallback} latestDownloadQue={latestDownloadQue} />;
}

function getAction(video, video_url, cacheCallback, extendCallback) {
    if (video && (video.status === 'cached' || video.status === 'completed')) {
        if (isEmpty(video.remove_time)) {
            return (
                <>
                    No Expiry Date
                </>
            );
        }
        return (
            <>
                Expire{moment(video.remove_time).isAfter(moment()) ? 's' : 'd'}{' '}
                {moment(video.remove_time)
                    .fromNow()}{' '}
                <Button onClick={extendCallback} type="link" className="btn-link">
                    extend
                </Button>
            </>
        );
    }

    if (video && video.status === 'queued' && !isEmpty(video_url.latestDownloadQue)) {
        return (
            `cache requested ${moment(video_url.latestDownloadQue.created_at).format('DD/MM/YYYY')} at ${moment(video_url.latestDownloadQue.created_at).format('HH:mm')}`
        );
    }
    return (
        <>
            <Button onClick={cacheCallback} type="link" className="btn-link">request cache</Button>
        </>
    );
}

export default function (video, video_url, videoFailed, videos_loading) {
    const status = video && video.status === 'cached' ? 'cached' : 'uncached';
    const statusText = ((video && video.status === 'discovered') || video.status) === 'queued' ? 'Uncached' : video.status;

    return (
        <div className="no-video">
            <Button
                className="back-button"
                type="link"
                onClick={() => {
                    const { setShouldShowSidebar, goPreviousPage, actions } = this.props;
                    if (goPreviousPage) {
                        actions.resetGoPreviousPageRequest();
                        window.history.back();
                    } else {
                        this.setState({ selectedVideoId: null });
                        setShouldShowSidebar(true);
                    }
                }}>
                <Icon name="chevron-left" />
                <span className="back-button-text">Back to video selection</span>
            </Button>
            <div className="driver__header">
                <div className="driver__name">
                    <span className={`indicator-activity ${video.online_status === '1' ? '' : 'red'}`} />
                    <span>{(video.asset_name) || 'Unavailable'}</span>
                    <span className="ml-1 secondary-text">(1 Mi)</span>
                </div>
                <div className="driver-cars">
                    <span>{(video.asset_registration) || 'Unavailable'}</span>
                    <span>{(video.asset_make) || 'Unavailable'}</span>
                </div>
            </div>
            <div className="no-video__player">
                {getVideo(video_url.stream, () => this.cacheVideo(video.id), videoFailed, video_url.latestDownloadQue, videos_loading)}
            </div>
            <div className="no-video__info mt-3">
                <div className="d-flex">
                    <div className="d-flex align-center">
                        <Icon name={`link-${status}`} />
                        <span className="ml-1">{`${statusText}`}</span>
                    </div>
                    <div className="d-flex align-center ml-2">
                        <span className="ml-1">
                            {getAction(video, video_url, () => this.cacheVideo(video.id), () => this.extendVideo(video.id))}
                        </span>
                    </div>
                </div>
                <Button onClick={() => (videoFailed ? message.warning('Unable to connect to device') : window.open(video_url.download))} className="btn btn-empty">
                    <Icon name="download" />
                    <span>Download video</span>
                </Button>
            </div>
        </div>
    );
}
