import { Button, Spin, notification } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { createWriteStream } from 'streamsaver';

function ExportVideoStatus({
    exportedCacheVideo, checkStatus, requestExportCacheVideo, isShareView, channels,
}) {
    const timeoutIdRef = useRef(null);
    const interval = 3000;
    const processingStatus = ['requested', 'processing'];
    let initalWatcherState = false;
    if (isShareView !== true) {
        initalWatcherState = !!(processingStatus.includes(exportedCacheVideo?.status));
    }
    const [startWatcher, setWatcher] = useState(initalWatcherState);
    // responseType
    const downloadFile = (url, fileName) =>
        // return fetch(url).then(res => {
        fetch(url).then((res) => {
            if (res.status !== 200) {
                throw new Error(res.status);
            }
            const fileStream = createWriteStream(fileName);
            const writer = fileStream.getWriter();
            if (res.body.pipeTo) {
                writer.releaseLock();
                return res.body.pipeTo(fileStream);
            }

            const reader = res.body.getReader();
            const pump = () => reader
                .read()
                .then(({ value, done }) => (done ? writer.close() : writer.write(value).then(pump)));

            return pump();
        });
    const exportVideo = () => {
        const downloadURL = exportedCacheVideo.aws_url;
        const { pathname } = new URL(downloadURL);
        const fileName = pathname.substring(pathname.lastIndexOf('/') + 1);
        downloadFile(downloadURL, fileName).catch((errr) => {
            notification.error({ message: 'Error', description: 'Failed to download file.' });
            console.error(errr);
        });
    };

    useEffect(() => {
        let _stopped = false;
        const pollingCallback = async () => {
            try {
                const result = await checkStatus();
                _stopped = !processingStatus.includes(result?.status);
            } finally {
                // Set timeout after it finished, unless stopped
                timeoutIdRef.current = !_stopped && setTimeout(
                    pollingCallback,
                    interval,
                );
            }
        };
        if (startWatcher) {
            pollingCallback();
        }
        return () => {
            _stopped = true; // prevent racing conditions
            setWatcher(false);
            clearTimeout(timeoutIdRef.current);
        };
    }, [startWatcher]);

    let exportVideoOption = (
        <Button
            type="rounded-default"
            onClick={async () => {
                await requestExportCacheVideo();
                setWatcher(true);
            }}
            className="btn"
            disabled={(!channels || channels.length < 1) && exportedCacheVideo?.status !== 'processed'}
        >
            Export video
        </Button>
    );

    if (exportedCacheVideo?.status === 'processing' || exportedCacheVideo?.status === 'requested') {
        exportVideoOption = (
            <span>
                <Spin spinning /> Exporting video...
            </span>
        );
    } else if (exportedCacheVideo?.status === 'processed') {
        exportVideoOption = (
            <Button
                type="primary"
                onClick={exportVideo}
                className="btn"
                disabled={(!channels || channels.length < 1) && exportedCacheVideo?.status !== 'processed'}
            >
                Export video
            </Button>
        );
    } else if (exportedCacheVideo?.status === 'errored') {
        exportVideoOption = (
            <span>
                Failed to export video
            </span>
        );
    }

    if (isShareView && exportedCacheVideo?.status !== 'processed') {
        exportVideoOption = null;
    }

    return (<div className="exporting-video">{exportVideoOption}</div>);
}
export default ExportVideoStatus;
