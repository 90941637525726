import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Spin } from 'antd';

import { isEmpty } from 'underscore';

import GridView from '../../../../../components/elements/GridView/index';
import * as eventsActions from '../../../../../core/events/eventsActions';
import * as assetActions from '../../../../../core/asset/assetActions';
import * as driverActions from '../../../../../core/driver/driverActions';
import CustomEmpty, { CustomEmptyType } from '../../../../../components/CustomEmpty';

class ReportTab extends PureComponent {
    constructor(props) {
        super(props);
    }

    onTableChange = (pagination, filters, sorter, extra) => {
        const { onTableChange } = this.props;
        onTableChange(pagination, filters, sorter, extra);
    };

    render() {
        const {
            notMovedReport,
            isFetching,
        } = this.props;
        let listReports = [];

        if (notMovedReport && notMovedReport.length > 0) {
            listReports = notMovedReport.map(report => {
                const tempReport = { ...report };
                tempReport.last_moved = report['LastMoved'];
                delete tempReport['LastMoved'];
                tempReport.last_location = report['LastKnownLocation'];
                delete tempReport['LastKnownLocation'];
                tempReport.duration = report['Not Moved Duration'];
                delete tempReport['Not Moved Duration'];
                return tempReport;
            });
        }
        const exportableColumns = listReports && listReports[0] ? Object.keys(listReports[0]) : [];

        return (
            <div className="report-table">
                {(isEmpty(listReports) && !isFetching) ? <CustomEmpty type={CustomEmptyType.Report} />
                    : (
                        <>
                            <span className='report-title'>Not Moved Report</span>
                            <br />
                            {!isFetching && (
                                <GridView
                                    exportableColumns={exportableColumns}
                                    data={listReports}
                                    onChange={this.onTableChange}
                                    disablePagination
                                    showExportButton
                                />
                            )}
                        </>
                    )}
            </div>
        );
    }
}

ReportTab.defaultProps = {
    notMovedReport: [],
    filters: {},
    actions: {},
};

ReportTab.propTypes = {
    notMovedReport: PropTypes.array,
    filters: PropTypes.object,
    isFetching: PropTypes.bool.isRequired,
    actions: PropTypes.object,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        notMovedReport: state.report.notMovedReport,
        isFetching: state.report.isFetching,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...eventsActions,
            ...assetActions,
            ...driverActions,
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ReportTab);
