import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Spin, Tooltip } from 'antd';
import moment from 'moment';
import { fetchApiAuth } from '../../core/utils/api';

import './deviceTimeline.scss';
import Spinner from '../Spinner';

export const DEVICE_TIMELINE_STATUS_IDLE_KEY = 'idle';
export const DEVICE_TIMELINE_STATUS_IDLE_TEXT = 'Idle';
export const DEVICE_TIMELINE_STATUS_MOVING_KEY = 'moving';
export const DEVICE_TIMELINE_STATUS_MOVING_TEXT = 'In Transit';
export const DEVICE_TIMELINE_STATUS_STOPPED_KEY = 'offline';
export const DEVICE_TIMELINE_STATUS_STOPPED_TEXT = 'Stopped';
export const DEVICE_TIMELINE_STATUS_MESSAGES = {
    [DEVICE_TIMELINE_STATUS_IDLE_KEY]: DEVICE_TIMELINE_STATUS_IDLE_TEXT,
    [DEVICE_TIMELINE_STATUS_MOVING_KEY]: DEVICE_TIMELINE_STATUS_MOVING_TEXT,
    [DEVICE_TIMELINE_STATUS_STOPPED_KEY]: DEVICE_TIMELINE_STATUS_STOPPED_TEXT,
    [DEVICE_TIMELINE_STATUS_STOPPED_KEY]: DEVICE_TIMELINE_STATUS_STOPPED_TEXT,
}


/**
 * Displays a timeline that represents a device's movement throughout the days as bars of moving, idle and stopped.
 * Accepts either predefined timeline data from the backend, or a device and date combination which the component will
 * use to fetch the data from the backend asynchronously.
 */
class DeviceTimeline extends Component {

    dateFormat = 'hh:mm:ss a';

    constructor(props) {
        super(props);
        this.state = {
            isFetching: false,
            fetchedTimeline: [],
            fetchedGeofenceTimeline: [],
            activeJourneyId: null,
            timelineRequestController: null,
            geofenceTimelineRequestController: null,
        };
    }

    componentDidMount() {
        const { data, geofenceData } = this.props;

        if ((!data && !geofenceData) || (
            (data && !data.length) && (geofenceData && !geofenceData.length)
        )) {
            this.fetchTimelineData();
        }
    }

    componentWillUnmount() {
        const { timelineRequestController, geofenceTimelineRequestController } = this.state;


        if (timelineRequestController) timelineRequestController.abort();
        if (geofenceTimelineRequestController) geofenceTimelineRequestController.abort();
    }

    fetchTimelineData = () => {
        const { deviceDetails } = this.props;

        if (!deviceDetails) return;
        if (!deviceDetails.date) return;
        if (!deviceDetails.assetId && !deviceDetails.deviceId) return;

        this.setState({ isFetching: true });

        const dateWithoutTime = deviceDetails.date.split(' ')[0];
        const body = {
            date: dateWithoutTime,
        };
        if (deviceDetails.assetId) body.asset_id = deviceDetails.assetId;
        else body.device_id = deviceDetails.deviceId;

        const timelineRequestController = new AbortController();
        const geofenceTimelineRequestController = new AbortController();
        const timelineFetchPromise = new Promise((resolve, reject) => {
            fetchApiAuth({
                method: 'POST',
                url: '/device/timeline',
                body,
                signal: timelineRequestController.signal,
            })
                .then((response) => {
                    this.setState({ fetchedTimeline: response.data });
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
        const geofenceTimelineFetchPromise = new Promise((resolve, reject) => {
            fetchApiAuth({
                method: 'POST',
                url: '/device/geofence-timeline',
                body,
                signal: geofenceTimelineRequestController.signal,
            })
                .then((response) => {
                    this.setState({ fetchedGeofenceTimeline: response.data });
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        });
        this.setState({
            timelineRequestController,
            geofenceTimelineRequestController,
        })
        Promise.all([timelineFetchPromise, geofenceTimelineFetchPromise])
            .then(() => {
                this.setState({
                    isFetching: false,
                    timelineRequestController: null,
                    geofenceTimelineRequestController: null,
                });
            })
            .catch(() => {
                this.setState({
                    isFetching: false,
                    timelineRequestController: null,
                    geofenceTimelineRequestController: null,
                });
            });
    }

    onMouseEnterHandler(block) {
        if (block.journey_id) {
            this.setState({ activeJourneyId: block.journey_id });
            return;
        }
        this.setState({ activeJourneyId: null });

    }

    onMouseLeaveHandler() {
        this.setState({ activeJourneyId: null });
    }

    render() {
        const {
            timelineWidth, scaleX, data, geofenceData, isInline, style,
        } = this.props;
        const {
            isFetching, fetchedTimeline, fetchedGeofenceTimeline, activeJourneyId,
        } = this.state;

        let timelineData = data;
        let geofenceTimelineData = geofenceData;
        if (!timelineData || !timelineData?.length) timelineData = fetchedTimeline || [];
        if (!geofenceTimelineData || !geofenceTimelineData?.length) geofenceTimelineData = fetchedGeofenceTimeline || [];
        const timelineComponent = (
            <div
                className={`device-timeline ${activeJourneyId ? 'focused-journey' : ''}`}
                style={{
                    width: timelineWidth * scaleX,
                    height: isInline ? '26px' : '32px',
                    position: isInline ? 'relative' : 'absolute',
                    top: isInline ? '0px' : '2px',
                    left: isInline ? '0px' : '6px',
                    marginTop: isInline ? '-4px' : '0px',
                    marginBottom: isInline ? '-4px' : '0px',
                    pointerEvents: isInline ? 'all' : 'none',
                    ...style,
                }}
            >
                {timelineData ? timelineData.map((block, i) => {
                    const onClick = () => {
                        if (block.journey_id) {
                            window.open(`/journey/${block.journey_id}`, '_blank').focus()
                        }
                    }

                    let journeyStart = null;
                    let journeyEnd = null;
                    const safeStatus = block.status ?? DEVICE_TIMELINE_STATUS_STOPPED_KEY;
                    const statusText = DEVICE_TIMELINE_STATUS_MESSAGES[safeStatus].trim();
                    const blockTimeRange = `${moment(block.start_time).format(this.dateFormat)} - ${moment(block.end_time).format(this.dateFormat)}`;
                    const blockLocation = block.location;

                    if (block.journey_start && block.journey_end) {
                        journeyStart = block.journey_start ? moment(block.journey_start).format(this.dateFormat) : null;
                        journeyEnd = block.journey_end ? moment(block.journey_end).format(this.dateFormat) : null;
                    }

                    return (
                        <Tooltip
                            key={`timeline-block-tooltip-${i}-${block.start_time}`}
                            title={(
                                <div>
                                    {block.journey_id ? (
                                        <>
                                            <div style={{ textAlign: 'center' }}>{block.journey_name}</div>
                                            <div style={{
                                                textAlign: 'center',
                                                margin: '0px'
                                            }}>
                                                <span>{journeyStart} - {journeyEnd}</span>
                                            </div>
                                        </>
                                    ) : null}

                                    <div style={{ textAlign: 'center' }}>{statusText}</div>
                                    <div style={{
                                        textAlign: 'center',
                                        margin: '0px'
                                    }}>
                                        {blockTimeRange}
                                    </div>
                                    {blockLocation ? (
                                        <div style={{ textAlign: 'center' }}>{blockLocation}</div>
                                    ) : null}
                                </div>
                            )}>
                            <div
                                key={`timeline-block-${i}-${block.start_time}`}
                                className={`block-${safeStatus} ${isInline ? ' inline' : ''} ${!block.journey_id ? 'not-clickable' : ''} ${activeJourneyId && activeJourneyId === block.journey_id ? 'is-focused' : ''}`}
                                onClick={onClick}
                                onMouseEnter={() => { this.onMouseEnterHandler(block) }}
                                onMouseLeave={this.onMouseLeaveHandler.bind(this)}
                                style={{
                                    width: (block.end - block.start) * scaleX,
                                    left: `${block.start * scaleX}px`,
                                }}
                            />
                        </Tooltip>
                    );
                }) : null}
                {geofenceTimelineData ? geofenceTimelineData.map((block, i) => {
                    const blockTimeRange = `${moment(block.start_time).format('hh:mm:ss A')} - ${moment(block.end_time).format('hh:mm:ss A')}`;
                    return (
                        <Tooltip
                            key={`geofence-timeline-block-tooltip-${i}-${block.start_time}`}
                            title={(
                                <div>
                                    <div style={{ textAlign: 'center' }}>
                                        In Geo-fence{block.geofence_names.length > 1 ? 's' : ''}:
                                        <br />
                                        {block.geofence_names.join(', ')}
                                    </div>
                                    <span>{blockTimeRange}</span>
                                </div>
                            )}
                        >
                            <div
                                key={`geofence-timeline-block-${i}`}
                                className={`geofence-block${isInline ? ' inline' : ''}`}
                                style={{
                                    width: (block.end - block.start) * scaleX,
                                    left: `${block.start * scaleX}px`,
                                }}
                            />
                        </Tooltip>
                    );
                }) : null}
            </div>
        );

        if (isFetching) {
            return (
                <Spinner 
                    loading={isFetching}
                    minHeight={25}
                    size={20}
                >
                    {timelineComponent}
                </Spinner>
            );
        } else {
            return timelineComponent;
        }
    }
}

DeviceTimeline.defaultProps = {
    timelineWidth: 86400, // seconds in a day
    scaleX: 1, // no scaling (width 86400)
    data: [],
    geofenceData: [],
    deviceDetails: null,
    isInline: false,
    style: { },
};

DeviceTimeline.propTypes = {
    timelineWidth: PropTypes.number, // length of timeline before scaling down to fit inside a target tag/div
    scaleX: PropTypes.number, // how much to scale the timeline by to fit into a target div, usually calculated by target width / timelineWidth
    data: PropTypes.array, // array of objects with start and end times and status, fetched from backend
    geofenceData: PropTypes.array, // array of objects with start and end times and status, fetched from backend
    deviceDetails: PropTypes.object, // consisting of deviceId/assetId and date, used to fetch the data on the fly, asynchronously
    isInline: PropTypes.bool, // whether to display the timeline inline or as an absolutely positioned block
    style: PropTypes.object, // additional styles to apply to the timeline
};

export default DeviceTimeline;
