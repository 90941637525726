import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import GridView from '../../../components/elements/GridView/index';
import { Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getTodayInServerFormat, getYesterdayInServerFormat } from '../../../core/utils/dateUtils';
import * as assetActions from '../../../core/asset/assetActions';
import { LocationFilled, NotificationFilled, PlugFilled, View, FlagFilled } from '@carbon/icons-react';

class AssetEventHistoryTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            searchParams: {},
            orderParams: {},
        };
    }

    componentDidMount() {
        const {
            actions, setLoadedState, assetEventHistoryTabLoaded, assetId,
        } = this.props;
        if (!assetEventHistoryTabLoaded) {
            actions.getAssetEventHistoryRequest({
                assetId,
                page: 1,
                date_from: getYesterdayInServerFormat(),
                date_to: getTodayInServerFormat(),
            });
            setLoadedState();
        }
    }

    onPageChange = (page, pageSize) => {
        const { onPageChange } = this.props;
        onPageChange(page, pageSize);
    };

    onTableChange = (pagination, filters, sorter, extra) => {
        const { actions, assetId, assetEventHistoryPagination } = this.props;
        const { orderParams } = this.state;
        // onTableChange(pagination, filters, sorter, extra);
        const params = { orderBy: `${sorter.order == 'descend' ? '-' : ''}${sorter.field}`, assetId };
        if (pagination && pagination.current == assetEventHistoryPagination.currentPage) {
            this.setState({ orderParams: params });
            actions.getAssetEventHistoryRequest({
                ...params,
            });
        }
    };

    render() {
        const {
            assetEventHistory, assetEventHistoryPagination, isFetching, singleAsset
        } = this.props;

        if (singleAsset.act_as_tracker) {
            return (
                <div className="h-centered-text-container">
                    <p>
                        Unavailable for this Asset - <a href="https://www.fleetclear.com/contact" target="_blank" rel="noreferrer">Click Here</a> to find out more
                    </p>
                </div>
            );
        }

        const listAssetEventHistory = assetEventHistory.map((event, eventIndex) => {
            const iconStyle = {};
            iconStyle.fontSize = '16px';
            iconStyle.color = '#CF2B28';
            iconStyle.verticalAlign = 'middle';
            const tempEvent = {};

            let eventTitle = event.event_name;

            if (event.event_type == 253) {
                if (event.green_driving_type == 0) {
                    eventTitle = 'Eco-friendly driving';
                } else if (event.green_driving_type == 1) {
                    eventTitle = 'Harsh accelerating';
                } else if (event.green_driving_type == 2) {
                    eventTitle = 'Harsh braking';
                } else if (event.green_driving_type == 3) {
                    eventTitle = 'Harsh cornering';
                }
            }

            tempEvent.event_type = (
                <Tooltip 
                    placement="top" 
                    title={eventTitle}
                >
                    <NotificationFilled 
                        size={20} 
                        style={{ 
                            color: 'var(--red)',
                            verticalAlign: 'middle',
                        }} 
                    />
                    &ensp;
                    {event.event_name}
                </Tooltip>
            );
            if (event.icon && event.icon !== '') {
                switch (event.icon) {
                    case 'alert':
                    case 'bell':
                        tempEvent.event_type = (
                            <Tooltip 
                                placement="top" 
                                title={eventTitle}
                            >
                                <NotificationFilled 
                                    size={20} 
                                    style={{ 
                                        color: 'var(--red)',
                                        verticalAlign: 'middle',
                                    }} 
                                />
                                &ensp;
                                {event.event_name}
                            </Tooltip>
                        );
                        break;
                    case 'api':
                        tempEvent.event_type = (
                            <Tooltip 
                                placement="top" 
                                title={eventTitle}
                            >
                                <PlugFilled 
                                    size={20} 
                                    style={{ 
                                        color: 'var(--red)',
                                        verticalAlign: 'middle',
                                    }}
                                />
                                &ensp;
                                {event.event_name}
                            </Tooltip>
                        );
                        break;
                    case 'environment':
                        tempEvent.event_type = (
                            <Tooltip 
                                placement="top" 
                                title={eventTitle}
                            >
                                <LocationFilled 
                                    size={20} 
                                    style={{ 
                                        color: 'var(--red)',
                                        verticalAlign: 'middle',
                                    }}
                                />
                                &ensp;
                                {event.event_name}
                            </Tooltip>
                        );
                        break;
                    case 'flag':
                        tempEvent.event_type = (
                            <Tooltip 
                                placement="top" 
                                title={eventTitle}
                            >
                                <FlagFilled
                                    size={20} 
                                    style={{ 
                                        color: 'var(--red)',
                                        verticalAlign: 'middle',
                                    }}
                                />
                                &ensp;
                                {event.event_name}
                            </Tooltip>
                        );
                        break;
                    default:
                        tempEvent.event_type = (
                            <Tooltip 
                                placement="top" 
                                title={eventTitle}
                            >
                                <NotificationFilled 
                                    size={20} 
                                    style={{ 
                                        color: 'var(--red)',
                                        verticalAlign: 'middle',
                                    }} 
                                />
                                &ensp;
                                {event.event_name}
                            </Tooltip>
                        );
                }
            }
            tempEvent.date = moment(event.date, 'YYYY-MM-DD').format('DD/MM/YYYY');
            tempEvent.time = moment(event.time, 'hh:mm A').format('hh:mm A');
            tempEvent.location = event.location_name;
            tempEvent.driver = <Link to={`/drivers/view/${event.user_id}`}>{event.full_name}</Link>;
            tempEvent.asset_reg = event.registration;
            tempEvent.actions = (
                <Tooltip title="View Event">
                    <Link 
                        to={`/events/custom-view/${event.id}/${event.device_id}`} 
                        className="actions-btn"
                    >
                        <View />
                    </Link>
                </Tooltip>
            );
            return tempEvent;
        });

        return (
            <GridView
                data={listAssetEventHistory}
                onChange={this.onTableChange}
                sortableColumns={['event_type', 'date', 'time', 'location', 'driver', 'asset_reg']}
                pagination={{
                    total: parseInt(assetEventHistoryPagination.totalRecords, 10),
                    showSizeChanger: false,
                    pageSize: parseInt(assetEventHistoryPagination.perPageCount, 10),
                    onChange: this.onPageChange,
                }} 
            />
        );
    }
}

AssetEventHistoryTab.defaultProps = {
    onPageChange: () => null,
};

AssetEventHistoryTab.propTypes = {
    onPageChange: PropTypes.func,
};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        assetEventHistory: state.asset.assetEventHistory,
        assetEventHistoryPagination: state.asset.assetEventHistoryPagination,
        isFetching: state.asset.isFetching,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...assetActions,
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AssetEventHistoryTab);
