/* global window */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Spin, Dropdown, Menu, Button } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { isEmpty } from 'underscore';

import GridView from '../../../../../components/elements/GridView/index';
import * as eventsActions from '../../../../../core/events/eventsActions';
import * as assetActions from '../../../../../core/asset/assetActions';
import * as driverActions from '../../../../../core/driver/driverActions';
import CustomEmpty, { CustomEmptyType } from '../../../../../components/CustomEmpty';

class ReportTab extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            unit: false,
        };
    }

    componentDidMount() {
        const { profile } = this.props;
        const { unit_of_measurement_preference } = profile;

        if (unit_of_measurement_preference === 'british-imperial') {
            this.setState({ unit: true });
        }
    }

    onPageChange = (page, pageSize) => {
        const { onPageChange } = this.props;
        onPageChange(page, pageSize);
    };

    onTableChange = (pagination, filters, sorter, extra) => {
        const { onTableChange } = this.props;
        onTableChange(pagination, filters, sorter, extra);
    };

    setUnit = (e) => {
        this.setState({ unit: e?.key === 'imperial' });
    };

    render() {
        const {
            utilisationReport,
            isFetching,
        } = this.props;
        let listReports = [];

        const { unit } = this.state;

        if (utilisationReport && utilisationReport.length > 0) {
            listReports = utilisationReport.map((report) => {
                const tempReport = {
                    ...report,
                };

                if (unit) {
                    if (tempReport.total_mileage && !Number.isNaN(+tempReport.total_mileage) && tempReport.total_mileage != 0) {
                        const miles = tempReport.total_mileage / 1.609344;
                        tempReport.total_mileage = miles.toFixed(2);
                    }
                }
                // delete tempReport.driver_id;
                return tempReport;
            });
        }
        const exportableColumns = listReports && listReports[0] ? (Object.keys(listReports[0]).filter((oK) => oK.toLowerCase() != 'actions')) : [];

        return (
            <div className="report-table">
                {(isEmpty(listReports) && !isFetching) ? <CustomEmpty type={CustomEmptyType.Report} />
                    : (
                        <>
                            <span className='report-title'>Utilisation Report</span>
                            <Dropdown overlay={(
                                <Menu onClick={this.setUnit}>
                                    <Menu.Item key="imperial">Miles</Menu.Item>
                                    <Menu.Item key="metric">Kilometers</Menu.Item>
                                </Menu>
                            )}>
                                <Button
                                    type='primary'
                                    className='export-dropdown-button'
                                    style={{
                                        right: 112,
                                        top: 20,
                                    }}
                                >
                                    {unit ? 'Miles' : 'Kilometers'}
                                    <DownOutlined />
                                </Button>
                            </Dropdown>
                            <br />
                            {!isFetching && (
                                <GridView
                                    showExportButton
                                    exportableColumns={exportableColumns}
                                    hiddenColumns={['asset_id']}
                                    data={listReports}
                                    onChange={this.onTableChange}
                                    disablePagination
                                />
                            )}
                        </>

                    )}
            </div>
        );
    }
}

ReportTab.defaultProps = {
    utilisationReport: [],
    filters: {},
    onPageChange: () => null,
    onTableChange: () => null,
    actions: {},
};

ReportTab.propTypes = {
    utilisationReport: PropTypes.array,
    filters: PropTypes.object,
    isFetching: PropTypes.bool.isRequired,
    onPageChange: PropTypes.func,
    onTableChange: PropTypes.func,
    actions: PropTypes.object,
    profile: PropTypes.object.isRequired,

};

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        utilisationReport: state.report.utilisationReport,
        utilisationReportPagination: state.report.utilisationReportPagination,
        isFetching: state.report.isFetching,
        profile: state.user.profile,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...eventsActions,
            ...assetActions,
            ...driverActions,
        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ReportTab);
