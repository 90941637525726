import React from 'react';
import { Tabs } from 'antd';

import Layout from '../../components/layout/Layout';
import FilterSidebar from '../../components/FilterSidebar';
import LiveVideosTab from './tabs/LiveVideosTab';
import CachedVideoTab from './tabs/CachedVideoTab';
import VideoSearchTab from './tabs/VideoSearchTab';
import CachedVideoFilter from './forms/CachedVideoFilter';
import LiveVideoFilter from './forms/LiveVideoFilter';
import VideoSearchFilter from './forms/VideoSearchFilter';

import './video.scss';

const { TabPane } = Tabs;

export default function () {
    const {
        match, assetId, user, assetReg, isFetchingSearchedVideo, isFetchingCachedVideo, isFetchingLiveVideo,
    } = this.props;
    const {
        showSideBar, locationSearch, mapOpen, videoSearchFiltered,
        latitude, longitude, geometry, openSidebar, showFilterSidebar, loadingDvr
    } = this.state;

    const { params } = match;
    const videoMain = this;

    let filterSideBar = (
        <VideoSearchFilter
            parent={videoMain}
            params={params}
            locationSearch={locationSearch}
            setLocationSearch={this.setLocationSearch}
            onSubmit={this.handleHistoricSearchFilter}
        />
    );

    let selectedTab = params?.selectedTab;
    if (user.permissions.historic_video_downloading !== true && user.permissions.live_video === true) {
        selectedTab = 'live-videos';
    }

    if (selectedTab === 'live-videos') {
        filterSideBar = <LiveVideoFilter onSubmit={this.liveVideoFilterSubmit} />;
    }
    if (selectedTab === 'cached-videos') {
        filterSideBar = <CachedVideoFilter onSubmit={this.handleRequestedFilter} />;
    }

    let destroyFilter = false;

    if (!showSideBar) {
        destroyFilter = true;
        filterSideBar = null;
    }

    const isLoading = isFetchingSearchedVideo || isFetchingCachedVideo || isFetchingLiveVideo;

    return (
        <Layout
            data-test="pages-video"
            className="video-page"
            title={<div>Video</div>}
            headerTitle={<div>Video Page</div>}
            menuPlacement="right"
            showFooter={false}
            loading={isLoading}
        >
            <div className={`content-wrap${user?.userCompany?.current_alert ? '-with-banner' : ''}`}>
                {showSideBar && filterSideBar ? (
                    <FilterSidebar
                        registerCloseSidebar={(f) => { this.closeSidebar = f; }}
                        displayFilter={filterSideBar}
                    >
                        {filterSideBar}
                    </FilterSidebar>
                ) : (
                    <>
                        <br />
                        <br />
                    </>
                )}
                <div 
                    className="content"
                    style={{ opacity: isLoading ? 0 : 1 }}
                >
                    <div className="content-inner">
                        <section className="section">
                            <Tabs
                                activeKey={selectedTab}
                                onTabClick={this.handleTabClick}
                                defaultActiveKey={selectedTab || 'cached-videos'}
                                animated={false}
                                className="tabs-restyled">

                                {user.permissions.historic_video_downloading === true
                                    ? (
                                        <TabPane tab="video search" key="video-search">
                                            <div className="section__body">
                                                <VideoSearchTab
                                                    checkDvrStatus={this.checkDvrStatus.bind(this)}
                                                    parent={videoMain}
                                                    geometry={geometry}
                                                    latitude={latitude}
                                                    longitude={longitude}
                                                    videoSearchFiltered={videoSearchFiltered}
                                                    locationSearch={locationSearch}
                                                    mapOpen={mapOpen}
                                                    setMapOpen={this.setMapOpen}
                                                    setLocationSearch={this.setLocationSearch}
                                                    setShouldShowSidebar={this.setShouldShowSidebar}
                                                    registerCallback={this.registerOnSearchCallback}
                                                    assetId={assetId} />
                                            </div>
                                        </TabPane>
                                    )
                                    : null}
                                {user.permissions.historic_video_downloading === true
                                    ? (
                                        <TabPane tab="downloads" key="cached-videos">
                                            <div className="section__body">
                                                {selectedTab === 'cached-videos'
                                                    ? (
                                                        <CachedVideoTab checkDvrStatus={this.checkDvrStatus.bind(this)} setShouldShowSidebar={this.setShouldShowSidebar} registerCallback={this.registerOnRequestFilterCallback} assetId={assetId} />
                                                    ) : null}
                                            </div>
                                        </TabPane>
                                    )
                                    : null}
                                {user.permissions.live_video === true ? (
                                    <TabPane tab="live video" key="live-videos">
                                        <div className="section__body">
                                            <LiveVideosTab checkDvrStatus={this.checkDvrStatus.bind(this)} params={params} setShouldShowSidebar={this.setShouldShowSidebar} />
                                        </div>
                                    </TabPane>
                                )
                                    : null}
                            </Tabs>
                        </section>
                    </div>
                </div>
            </div>
        </Layout>
    );
}
