import React, { ReactNode } from 'react';
import { AdvancedMarker, useAdvancedMarkerRef } from '@vis.gl/react-google-maps';
import { Location } from '../../index';
import Popup from '../Popup';
import { AssetPopupContentProps } from '../Popup/AssetPopupContent';
import { EventPopupContentProps } from '../Popup/EventPopupContent';

import './marker.scss';

/**
 * Handles the mouse over event for the marker.
 * @param openTimeout The timeout for opening the popup.
 * @param setOpenTimeout The state setter for the open timeout.
 * @param closeTimeout The timeout for closing the popup.
 * @param setCloseTimeout The state setter for the close timeout.
 * @param setPopupIsVisible The state setter for the popup visibility.
 */
const onMouseOver = (
    openTimeout: NodeJS.Timeout, 
    setOpenTimeout: React.Dispatch<React.SetStateAction<NodeJS.Timeout>>, 
    closeTimeout: NodeJS.Timeout, 
    setCloseTimeout: React.Dispatch<React.SetStateAction<NodeJS.Timeout>>, 
    setPopupIsVisible: React.Dispatch<React.SetStateAction<boolean>> 
): void => {
    if (closeTimeout) {
        clearTimeout(closeTimeout as NodeJS.Timeout);
        setCloseTimeout(null);
    }
    if (!openTimeout) {
        setOpenTimeout(setTimeout(() => setPopupIsVisible(true), 500));
    }
}

/**
 * Handles the mouse out event for the marker.
 * @param openTimeout The timeout for opening the popup.
 * @param setOpenTimeout The state setter for the open timeout.
 * @param closeTimeout The timeout for closing the popup.
 * @param setCloseTimeout The state setter for the close timeout.
 * @param setPopupIsVisible The state setter for the popup visibility.
 */
const onMouseOut = (
    openTimeout: NodeJS.Timeout, 
    setOpenTimeout: React.Dispatch<React.SetStateAction<NodeJS.Timeout>>, 
    closeTimeout: NodeJS.Timeout, 
    setCloseTimeout: React.Dispatch<React.SetStateAction<NodeJS.Timeout>>, 
    setPopupIsVisible: React.Dispatch<React.SetStateAction<boolean>> 
): void => {
    if (!closeTimeout) {
        setCloseTimeout(setTimeout(() => setPopupIsVisible(false), 500));
    }
    if (openTimeout) {
        clearTimeout(openTimeout as NodeJS.Timeout);
        setOpenTimeout(null);
    }
}

type MarkerProps = {
    key?: string;
    location?: Location;
    zIndex?: number;
    markerContent?: ReactNode | null;
    popupData?: AssetPopupContentProps | EventPopupContentProps | null;
};

/**
 * Combines an AdvancedMarker with a Popup.
 */
const Marker: React.FC<MarkerProps> = ({
    key = '',
    location = { lat: 0, lng: 0 },
    zIndex = 0,
    markerContent = null,
    popupData = null,
}) => {
    const [ markerRef, marker ] = useAdvancedMarkerRef();
    const [ popupIsVisible, setPopupIsVisible ] = React.useState<boolean>(false);
    const [ openTimeout, setOpenTimeout ] = React.useState<NodeJS.Timeout | null>(null);
    const [ closeTimeout, setCloseTimeout ] = React.useState<NodeJS.Timeout | null>(null);

    return (
        <></>
    );
}

export default Marker;