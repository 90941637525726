/* global window */
import React, { Component } from 'react';
import { Form } from '@ant-design/compatible';
import {
    DatePicker, Select, Button, Radio, message, Input, TimePicker, Row, Col, Tooltip,
} from 'antd';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { isEmpty, indexOf } from 'underscore';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { Erase, Calendar } from '@carbon/icons-react';
import * as eventsActions from '../../../core/events/eventsActions';
import * as assetActions from '../../../core/asset/assetActions';
import * as driverActions from '../../../core/driver/driverActions';
import * as deviceActions from '../../../core/device/deviceActions';
import { formatEventTypes } from '../../../core/utils/functions';
import DateRangePicker from '../../../components/DateRangePicker';

dayjs.extend(customParseFormat);

export const DATA_MODE_ASSETS_OR_DRIVERS = '0'; // Allows the user to choose to filter by assets OR drivers
export const DATA_MODE_ASSETS_OR_DIVISIONS = '1'; // Allows the user to choose to filter by assets OR divisions
export const DATA_MODE_DRIVERS_AND_DIVISIONS = '2'; // Allows the user to choose to filter by drivers AND divisions
export const DATE_MODE_RANGE = '0'; // Allows the user to filter between two dates
export const DATE_MODE_FREQUENCY = '1'; // Allows the user to filter by day, week, month
export const DATETIME_MODE_RANGE = '2'; // gives one range picker with time

class ReportFilter extends Component {
    constructor(props) {
        super(props);

        this.state = {
            asset_or_driver: 'assets',
            division_or_asset: 'assets',
            selectedDivisions: undefined,
            report_type: 'mpg',
            asset_id: [],
            driver: [],
            dateFrom: dayjs(),
            dateTo: dayjs(),
            division_id: [],
            journey_or_day: 'days',
            existing_or_custom_geofence: props.filters?.existing_or_custom_geofence || 'existing',
            existingGeofenceId: undefined,
            include_weekends: 'true',
            newGeoFencePath: null,
        };
    }

    componentDidMount() {
        const { actions } = this.props;

        actions.getAssetOptionsRequest({ is_report: true });
        actions.getDriverListRequest();
        actions.getDivisionsRequest();
        actions.getCompanyAllTriggersRequest();
    }

    componentDidUpdate(prevProps) {
        if (this.props?.newGeoFencePath && prevProps?.newGeoFencePath !== this.props?.newGeoFencePath) {
            this.setState({ newGeoFencePath: this.props.newGeoFencePath });
        }
    }

    submitForm = (e) => {
        const { onSubmit, form, showFuelReportType } = this.props;

        e.preventDefault();

        form.validateFields((err, values) => {
            if (!err) {
                if (showFuelReportType) {
                    let allowSubmit = isEmpty(err);
                    const petrolPrice = form.getFieldValue('petrol_price');
                    const dieselPrice = form.getFieldValue('diesel_price');
                    const reportType = form.getFieldValue('report_type');

                    if (reportType === 'fuel_cost' && isEmpty(petrolPrice) && isEmpty(dieselPrice)) {
                        message.error('Please enter either petrol price or diesel price!');
                        allowSubmit = false;
                    }

                    if (allowSubmit === true) {
                        onSubmit(values);
                    }
                } else {
                    onSubmit(values);
                }
            }
        });
    };

    render() {
        const {
            form, assetOptions, driverList, divisionList, dataMode, dateMode, showDivision,
            showFuelReportType, showDrivingTimeReportTimeType, showTimesheetReportRowType,
            onChangeViewType, showWorkingHours, allTriggers, showGeoFence, geofenceDrawingEnabled,
            geofenceSaved, showEventTypes, event_types, showWorkingHoursRange, insideOutsideSelection,
            showTimeBackSelection, weekendToggle, allowDivisionByPass, telematicsRetentionDays,
        } = this.props;
        const { getFieldDecorator, getFieldValue } = form;
        const {
            asset_or_driver, division_or_asset, selectedDivisions, newGeoFencePath,
            report_type, asset_id, driver, dateTo, division_id, journey_or_day,
            existing_or_custom_geofence,
        } = this.state;

        const dateFormat = 'DD/MM/YYYY';
        let filteredDriverList = [];
        if (selectedDivisions === undefined) {
            filteredDriverList = driverList;
        } else if (selectedDivisions.length < 1) {
            filteredDriverList = driverList;
        } else {
            for (let i = 0; i < selectedDivisions.length; i++) {
                const selectedDivision = selectedDivisions[i];
                const matches = driverList.filter((d) => parseInt(d.division_id, 10) === selectedDivision);
                filteredDriverList.push(...matches);
            }
        }
        let filteredAssetList = [];
        if (selectedDivisions === undefined) {
            filteredAssetList = assetOptions;
        } else if (selectedDivisions.length < 1) {
            filteredAssetList = assetOptions;
        } else {
            for (let j = 0; j < selectedDivisions.length; j++) {
                const selectedDivision = selectedDivisions[j];
                const matches = assetOptions.filter((a) => parseInt(a.division_id, 10) === selectedDivision);
                filteredAssetList.push(...matches);
            }
        }
        const divisionComponent = (
            <Form.Item label="Division">
                {getFieldDecorator('division', { initialValue: selectedDivisions })(
                    <Select
                        showSearch
                        allowClear={<Erase size={12} />}
                        filterOption={(txt, obj) => (obj.props.children.toLowerCase().includes(txt.toLowerCase()))}
                        placeholder="Select Division"
                        mode="multiple"
                        onChange={(value) => {
                            const { setFieldsValue } = this.props.form;

                            this.setState({ selectedDivisions: value });
                            setTimeout(() => {
                                setFieldsValue({
                                    driver: [],
                                    asset_id: [],
                                });
                            }, 0);
                        }}>
                        {divisionList.map((d) => (
                            <Select.Option
                                key={d.id}
                                value={d.id}>
                                {d.name}
                            </Select.Option>
                        ))}
                    </Select>,
                )}
            </Form.Item>
        );
        const filteredDriversComponent = (
            <Form.Item label="Driver">
                {getFieldDecorator('driver', { initialValue: driver })(
                    <Select
                        showSearch
                        allowClear={<Erase size={12} />}
                        filterOption={(txt, obj) => (obj.props.children.toLowerCase().includes(txt.toLowerCase()))}
                        placeholder="Select Driver"
                        mode="multiple"
                        onChange={(val) => { this.setState({ driver: val }); }}
                    >
                        {filteredDriverList.map((d) => (
                            <Select.Option
                                key={d.id}
                                value={d.id}>
                                {d.name}
                            </Select.Option>
                        ))}
                    </Select>,
                )}
            </Form.Item>
        );
        let updatedEventTypes = null;
        if (showEventTypes) {
            updatedEventTypes = formatEventTypes(event_types);
        }
        let initialGeofence = this.props.filters?.existingGeofenceId
            ? this.props.filters?.existingGeofenceId
            : this.state.existingGeofenceId;

        return (
            <Form
                onSubmit={this.submitForm}
                layout="vertical"
            >
                {showGeoFence ? (
                    <div style={{ marginBottom: '12px' }}>
                        <Form.Item label="Method">
                            {getFieldDecorator('existing_or_custom_geofence', {
                                initialValue: existing_or_custom_geofence,
                            })(
                                <Radio.Group onChange={(e) => {
                                    if (e.target.value === 'custom') {
                                        this.props.toggleDrawGeoFence(true);
                                    } else {
                                        this.props.toggleDrawGeoFence(false);
                                    }
                                    const update = { existing_or_custom_geofence: e.target.value };

                                    this.setState(update, () => {
                                        this.props.onChangeViewType(update);
                                    });
                                }}>
                                    <Radio value="existing">Saved Geo-fences</Radio>
                                    <br />
                                    <Radio value="custom">Draw on map</Radio>
                                </Radio.Group>
                            )}
                        </Form.Item>
                        <div style={{ position: 'absolute', top: '36px', right: '160px' }}>
                            {((form.getFieldValue('existing_or_custom_geofence') === 'custom' && !geofenceDrawingEnabled) || (newGeoFencePath && newGeoFencePath.length)) ? (
                                <p style={{
                                    fontSize: '12px',
                                    lineHeight: '12px',
                                    height: '20px',
                                    paddingTop: '4px'
                                }}>
                                    <a onClick={() => { this.props.toggleDrawGeoFence(true); }}>Reset</a>
                                </p>
                            ) : null}
                            {((form.getFieldValue('existing_or_custom_geofence') === 'custom' && !geofenceDrawingEnabled && !geofenceSaved) || (newGeoFencePath && newGeoFencePath.length)) ? (
                                <p style={{
                                    fontSize: '12px',
                                    lineHeight: '12px',
                                    height: '20px'
                                }}>
                                    <a onClick={() => { this.props.saveGeoFence(); }}>Save</a>
                                </p>
                            ) : null}
                        </div>
                        {form.getFieldValue('existing_or_custom_geofence') === 'existing' ? (
                            <Form.Item>
                                {getFieldDecorator('existingGeofenceId', {
                                    initialValue: initialGeofence
                                })(
                                    <Select
                                        showSearch
                                        allowClear={<Erase size={12} />}
                                        filterOption={(txt, obj) => (obj.props.children.toLowerCase().includes(txt.toLowerCase()))}
                                        placeholder="Select Geo-fence"
                                        onChange={(val) => {
                                            let update = { existingGeofenceId: null };
                                            if (!val) {
                                                this.props.geofenceSelected(null);
                                            } else {
                                                const trigger = allTriggers.find((item) => item.id == val);
                                                this.props.geofenceSelected(trigger);
                                                update = { existingGeofenceId: val };
                                            }
                                            this.setState(update, () => {
                                                this.props.onChangeViewType(update);
                                            });
                                        }}>
                                        {allTriggers.map((a) => (
                                            <Select.Option
                                                key={a.id}
                                                value={a.id}>
                                                {a.name}
                                            </Select.Option>
                                        ))}
                                    </Select>,
                                )}
                            </Form.Item>
                        ) : null}
                    </div>
                ) : null}

                {dataMode === DATA_MODE_ASSETS_OR_DRIVERS ? (
                    <>
                        <Form.Item label="Report Type">
                            {getFieldDecorator('asset_or_driver', {
                                initialValue: asset_or_driver,
                                rules: [
                                    {
                                        required: false,
                                        message: 'Please select report type!',
                                    },
                                ],
                            })(
                                <Radio.Group onChange={(e) => this.setState({
                                    asset_or_driver: e.target.value,
                                    asset_id: [],
                                    driver: [],
                                })}>
                                    <Radio value="drivers">Drivers</Radio>
                                    <br />
                                    <Radio value="assets">Assets</Radio>
                                </Radio.Group>,
                            )}
                        </Form.Item>
                        {showDivision ? divisionComponent : null}
                        {asset_or_driver === 'drivers' ? filteredDriversComponent : null}
                        {asset_or_driver === 'assets' ? (
                            <Form.Item label="Asset">
                                {getFieldDecorator('asset_id', { initialValue: asset_id })(
                                    <Select
                                        showSearch
                                        allowClear={<Erase size={12} />}
                                        mode="multiple"
                                        filterOption={(txt, obj) => {
                                            if (obj?.disabled) {
                                                return false;
                                            } else {
                                                return obj.children.toLowerCase().includes(txt.toLowerCase());
                                            }
                                        }}
                                        placeholder="Select Asset"
                                        onChange={(val) => this.setState({ asset_id: val })}>
                                        {filteredAssetList.map((a) => {

                                            if (a.act_as_tracker) {
                                                const toolTipTitle = (
                                                    <span>Unavailable for this Asset - <a href="https://www.fleetclear.com/contact" target="_blank" rel="noreferrer">Click here</a> to find out more</span>
                                                );

                                                return (
                                                    <Select.Option
                                                        key={a.id}
                                                        value={a.id}
                                                        disabled
                                                        style={{
                                                            backgroundColor: '#f3f2f2',
                                                            borderRadius: 0,
                                                            marginBottom: '1px',
                                                        }}
                                                    >
                                                        <Tooltip title={toolTipTitle}>
                                                            <div>
                                                                {a.name === a.reg || a.reg === '' ? a.name : `${a.name} [${a.reg}]`}
                                                            </div>
                                                        </Tooltip>
                                                    </Select.Option>
                                                );
                                            }

                                            return (
                                                <Select.Option
                                                    key={a.id}
                                                    value={a.id}>
                                                    {a.name === a.reg || a.reg === '' ? a.name : `${a.name} [${a.reg}]`}
                                                </Select.Option>
                                            );
                                        })}
                                    </Select>,
                                )}
                            </Form.Item>
                        ) : null}
                    </>
                ) : dataMode === DATA_MODE_ASSETS_OR_DIVISIONS ? (
                    <>
                        <Form.Item label="Report Type">
                            {getFieldDecorator('division_or_asset', {
                                initialValue: division_or_asset,
                                rules: [
                                    {
                                        required: false,
                                        message: 'Please select report type!',
                                    },
                                ],
                            })(
                                <Radio.Group onChange={(e) => this.setState({
                                    division_or_asset: e.target.value,
                                    asset_id: [],
                                    division_id: [],
                                })}>
                                    <Radio value="assets">Assets</Radio>
                                    <br />
                                    <Radio value="divisions">Divisions</Radio>
                                </Radio.Group>,
                            )}
                        </Form.Item>
                        {division_or_asset === 'divisions' ? (
                            <Form.Item label="Division">
                                {getFieldDecorator('division_id', {
                                    initialValue: division_id,
                                    rules: [
                                        {
                                            required: !allowDivisionByPass,
                                            message: 'Please select a divison!',
                                        },
                                    ],
                                })(
                                    <Select
                                        showSearch
                                        allowClear={<Erase size={12} />}
                                        mode="multiple"
                                        filterOption={(txt, obj) => (obj.props.children.toLowerCase().includes(txt.toLowerCase()))}
                                        placeholder="Select Division"
                                        onChange={(val) => this.setState({ division_id: val })}>
                                        {divisionList.map((d) => (
                                            <Select.Option
                                                key={d.id}
                                                value={d.id}>
                                                {d.name}
                                            </Select.Option>
                                        ))}
                                    </Select>,
                                )}
                            </Form.Item>
                        ) : null}
                        {division_or_asset === 'assets' ? (
                            <Form.Item label="Asset">
                                {getFieldDecorator('asset_id', {
                                    initialValue: asset_id,
                                    rules: [
                                        {
                                            required: false,
                                            message: 'Please select an asset!',
                                        },
                                    ],
                                })(
                                    <Select
                                        showSearch
                                        allowClear={<Erase size={12} />}
                                        mode="multiple"
                                        filterOption={(txt, obj) => (obj.props.children.toLowerCase().includes(txt.toLowerCase()))}
                                        placeholder="Select Asset"
                                        onChange={(val) => this.setState({ asset_id: val })}>
                                        {assetOptions.map((a) => (
                                            <Select.Option
                                                key={a.id}
                                                value={a.id}>
                                                {a.name === a.reg || a.reg === '' ? a.name : `${a.name} [${a.reg}]`}
                                            </Select.Option>
                                        ))}
                                    </Select>,
                                )}
                            </Form.Item>
                        ) : null}
                    </>
                ) : dataMode === DATA_MODE_DRIVERS_AND_DIVISIONS ? (
                    <>
                        {divisionComponent}
                        {filteredDriversComponent}
                    </>
                ) : null}

                {showFuelReportType ? (
                    <>
                        <Form.Item label="Report Type">
                            {getFieldDecorator('report_type', {
                                initialValue: report_type,
                                rules: [
                                    {
                                        required: true,
                                        message: 'Please select report type!',
                                    },
                                ],
                            })(
                                <Select
                                    onChange={(val) => this.setState({ report_type: val })}
                                    placeholder="Select Report Type"
                                    showSearch
                                    allowClear={<Erase size={12} />}
                                >
                                    <Select.Option value="mpg">MPG Report</Select.Option>
                                    <Select.Option value="fuel_volume">Fuel Volume</Select.Option>
                                    <Select.Option value="fuel_cost">Fuel Cost (£GBP)</Select.Option>
                                </Select>,
                            )}
                        </Form.Item>
                        {report_type === 'fuel_cost' ? (
                            <>
                                <Form.Item label="Petrol Price">
                                    {getFieldDecorator('petrol_price', {})(
                                        <Input placeholder="Enter Petrol Price Per Litre" />,
                                    )}
                                </Form.Item>
                                <Form.Item label="Diesel Price">
                                    {getFieldDecorator('diesel_price', {})(
                                        <Input placeholder="Enter Diesel Price Per Litre" />,
                                    )}
                                </Form.Item>
                            </>
                        ) : null}
                    </>
                ) : null}

                {showDrivingTimeReportTimeType ? (
                    <>
                        <Form.Item label="Display time">
                            {getFieldDecorator('time_type', {
                                initialValue: 'usage',
                                rules: [
                                    {
                                        required: true,
                                        message: 'Please select Display Time!',
                                    },
                                ],
                            })(
                                <Select
                                    placeholder="Select Report Type"
                                >
                                    <Select.Option value="usage">Usage Time</Select.Option>
                                    <Select.Option value="down-time">Down Time</Select.Option>
                                </Select>,
                            )}
                        </Form.Item>
                    </>
                ) : null}

                {showTimesheetReportRowType && onChangeViewType ? (
                    <>
                        <Form.Item label="Collate By">
                            {getFieldDecorator('journey_or_day', {
                                initialValue: journey_or_day,
                                rules: [
                                    {
                                        required: true,
                                        message: 'Please select row type!',
                                    },
                                ],
                            })(
                                <Radio.Group onChange={(e) => {
                                    onChangeViewType(e.target.value);
                                    this.setState({ journey_or_day: e.target.value });
                                }}>
                                    <Radio value="journeys">Journeys</Radio>
                                    <br />
                                    <Radio value="days">Days</Radio>
                                </Radio.Group>,
                            )}
                        </Form.Item>
                    </>
                ) : null}

                {showEventTypes ? (
                    <Form.Item label="Event type">
                        {getFieldDecorator('event_types')(
                            <Select
                                mode="multiple"
                                placeholder="Select Event Type"
                                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                showSearch
                                allowClear={<Erase size={12} />}
                            >
                                {updatedEventTypes.map((eventType) => (
                                    <Select.Option
                                        key={eventType.id}
                                        value={eventType.id}
                                    >
                                        {eventType.key}
                                    </Select.Option>
                                ))}
                            </Select>,
                        )}
                    </Form.Item>
                ) : null}

                {dateMode === DATE_MODE_RANGE ? (
                    <>
                        <DateRangePicker
                            getFieldDecorator={getFieldDecorator}
                            getFieldValue={getFieldValue}
                            minDate={dayjs().subtract(telematicsRetentionDays, 'days')}
                            dateFromInitialValue={!isEmpty(this.props.filters?.dateRange)
                                ? dayjs(this.props.filters.dateRange[0])
                                : dayjs()
                            }
                            dateFromOnChange={(val) => this.setState({ date_from: val.startOf('day') })}
                            dateToInitialValue={!isEmpty(this.props.filters?.dateRange)
                                ? dayjs(this.props.filters.dateRange[1])
                                : dayjs()
                            }
                            dateToOnChange={(val) => this.setState({ date_to: val.endOf('day') })}
                        />
                    </>
                ) : dateMode === DATE_MODE_FREQUENCY ? (
                    <>
                        <Form.Item label="Frequency">
                            {getFieldDecorator('frequency', {
                                initialValue: '1',
                                rules: [
                                    {
                                        required: true,
                                        message: 'Please select Frequency!',
                                    },
                                ],
                            })(
                                <Select
                                    placeholder="Select Frequency"
                                >
                                    <Select.Option value="1">Daily</Select.Option>
                                    <Select.Option value="7">Weekly</Select.Option>
                                    <Select.Option value="30">Monthly</Select.Option>
                                </Select>,
                            )}
                        </Form.Item>
                        <Form.Item label="Date to">
                            {getFieldDecorator('date_to', {
                                initialValue: dateTo,
                                rules: [
                                    {
                                        required: true,
                                        message: 'Please select a date to!',
                                    },
                                ],
                            })(
                                <DatePicker
                                    placeholder="Date To"
                                    format={dateFormat}
                                    onChange={(val) => this.setState({ dateTo: val })}
                                    minDate={dayjs().subtract(telematicsRetentionDays, 'days')}
                                    allowClear={false}
                                    suffixIcon={<Calendar />}
                                />,
                            )}
                        </Form.Item>
                    </>
                ) : null}

                {showWorkingHours ? (
                    <Form.Item label="Target Hours">
                        {getFieldDecorator('target_hours', {
                            rules: [
                                {
                                    required: true,
                                    message: 'Please select Target Hours!',
                                },
                                {
                                    validator: (rule, value, callback) => {
                                        if (value < 1 || value > 1000) {
                                            callback('Target hours should be between 1 and 1000');
                                        } else {
                                            callback();
                                        }
                                    },
                                },
                            ],
                        })(
                            <Input
                                type="number"
                                placeholder="Enter Target Hours"
                                min={1}
                                max={1000}
                            />,
                        )}
                    </Form.Item>
                ) : null}

                {insideOutsideSelection ? (
                    <Form.Item label="Inside/outside the hours?">
                        {getFieldDecorator('inside_outside', {
                            rules: [
                                {
                                    required: true,
                                    message: 'Please select an option',
                                },
                            ],
                        })(
                            <Radio.Group>
                                <Radio value="inside" selected>Inside</Radio>
                                <Radio value="outside">Outside</Radio>
                            </Radio.Group>,
                        )}
                    </Form.Item>
                ) : null}

                {showWorkingHoursRange ? (
                    <Row>
                        <Col span={12}>
                            <Form.Item label="Start Hour">
                                {getFieldDecorator('start_hour', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Please input start hour',
                                        },
                                        {
                                            pattern: /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/,
                                            message: 'Time format should be HH:MM',
                                        }
                                    ],
                                })(
                                    <Input
                                        type='text'
                                        placeholder="HH:MM"
                                    />
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="End Hour">
                                {getFieldDecorator('end_hour', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Please input end hour',
                                        },
                                    ],
                                })(
                                    <Input
                                        type='text'
                                        placeholder="HH:MM"
                                    />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                ) : null}

                {weekendToggle ? (
                    <Form.Item label="Include Weekends">
                        {getFieldDecorator('include_weekends', {
                            initialValue: 'true',
                            rules: [
                                {
                                    required: false,
                                    message: 'Please select if you would like to include weekends or not!',
                                },
                            ],
                        })(
                            <Radio.Group onChange={(e) => this.setState({ include_weekends: e.target.value })}>
                                <Radio value="true">Yes</Radio>
                                <br />
                                <Radio value="false">No</Radio>
                            </Radio.Group>,
                        )}
                    </Form.Item>
                ) : null}

                {showTimeBackSelection ? (
                    <Form.Item label="Day Length">
                        {getFieldDecorator('number_of_days', {
                            rules: [
                                {
                                    required: false,
                                    message: 'Please select the length in days',
                                },
                            ],
                        })(
                            <Select
                                placeholder="Select Length In Days"
                                showSearch
                                allowClear={<Erase size={12} />}
                            >
                                {[...Array(40).keys()].map((i) => (
                                    <Select.Option key={i + 1} value={i + 1}>
                                        {i + 1}
                                    </Select.Option>
                                ))}
                            </Select>,
                        )}
                    </Form.Item>
                ) : null}

                <div className="mt-2 mb-1 text-center">
                    <Button
                        htmlType="submit"
                        type="primary"
                    >
                        Filter
                    </Button>
                </div>
            </Form>
        );
    }
}

ReportFilter.propTypes = {
    form: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    actions: PropTypes.object.isRequired,
    assetOptions: PropTypes.array,
    driverList: PropTypes.array,
    divisionList: PropTypes.array,
    filters: PropTypes.object.isRequired,
    dataMode: PropTypes.string.isRequired,
    dateMode: PropTypes.string.isRequired,
    showDivision: PropTypes.bool,
    showFuelReportType: PropTypes.bool,
    showDrivingTimeReportTimeType: PropTypes.bool,
    showTimesheetReportRowType: PropTypes.bool,
    onChangeViewType: PropTypes.func,
    newGeoFencePath: PropTypes.array,
};

ReportFilter.defaultProps = {
    form: {},
    onSubmit: () => { },
    actions: {},
    assetOptions: [],
    driverList: [],
    divisionList: [],
    filters: {},
    dataMode: DATA_MODE_ASSETS_OR_DRIVERS,
    dateMode: DATE_MODE_RANGE,
    showDivision: false,
    showFuelReportType: false,
    showDrivingTimeReportTimeType: false,
    showTimesheetReportRowType: false,
    allowDivisionByPass: false,
    onChangeViewType: () => { },
    newGeoFencePath: [],
};

const ReportFilterForm = Form.create({ name: 'event_filter_form' })(ReportFilter);

function mapStateToProps(state, ownProps) {
    return {
        ...ownProps,
        event_types: state.asset.event_types,
        assetOptions: state.asset.options,
        driverList: state.driver.driverList,
        divisionList: state.asset.divisions,
        allTriggers: state.device.allTriggers,
        telematicsRetentionDays: state.user.userCompany.telematics_retention_days,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators({
            ...eventsActions,
            ...assetActions,
            ...driverActions,
            ...deviceActions,

        }, dispatch),
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ReportFilterForm);
