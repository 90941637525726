import { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { findIndex, isEmpty } from 'underscore';
import { message } from 'antd';
import moment from 'moment';
import DOM from './driversView';
import * as DriverActions from '../../core/driver/driverActions';
import * as AssetActions from '../../core/asset/assetActions';
import * as DivisionActions from '../../core/division/divisionActions';
import { getTodayInServerFormat, getYesterdayInServerFormat } from '../../core/utils/dateUtils';

class DriversView extends Component {
    constructor(props) {
        super(props);
        this.view = DOM;

        this.state = {
            modalAssignToAssets: false,
            modalEdit: false,
            selectedAsset: {},
            currentTabKey: '1',
            journeyHistoryTabLoaded: false,
            journeyHistoryTabFilter: {
                asset_info: '',
                division: '',
                registration_year: '',
                make: '',
                fuel_type: '',
                current_location: '',
                page: 1,
                date_from: getYesterdayInServerFormat(),
                date_to: getTodayInServerFormat(),
            },
            eventsHistoryTabLoaded: false,
            eventsHistoryTabFilter: {
                asset_info: '',
                division: '',
                registration_year: '',
                make: '',
                fuel_type: '',
                current_location: '',
                page: 1,
                date_from: getYesterdayInServerFormat(),
                date_to: getTodayInServerFormat(),
            },
            serviceAlertsTabLoaded: false,
            serviceAlertsTabFilter: {
                asset_info: '',
                division: '',
                registration_year: '',
                make: '',
                fuel_type: '',
                current_location: '',
                page: 1,
            },
            deleteDataConfirmButtonVisible: false,
            isDeleteDataModalVisible: false,
            deleteDataModalInput: null,
            displayFilter: true,
        };
    }

    componentDidMount() {
        const {
            driverId, actions, divisions, driverList,
        } = this.props;

        actions.getSingleDriverRequest({ driverId });
        actions.tagLimitReachedRequest({ driver_id: driverId });
        actions.getAssetListRequest();
        if (divisions.length == 0) {
            actions.getDivisionOptionsRequest();
        }
        if (driverList.length <= 0) {
            actions.getDriverListRequest();
        }
    }

    componentDidUpdate(prevProps) {
        const { driver, actions, driverId } = this.props;
        
        const { assignAssetFetching } = driver;
        if (!assignAssetFetching && assignAssetFetching != prevProps.driver.assignAssetFetching) {
            actions.getSingleDriverRequest({ driverId });
        }
    }

    componentWillUnmount() {
        const { actions } = this.props;

        actions.setDriverJourneyFilters({});
    }

    showModal = () => {
        this.setState({ isDeleteDataModalVisible: true });
    };

    saveDeleteText = (text) => {
        this.setState({ deleteDataModalInput: text });
        if (text.toLowerCase() === 'delete') {
            this.setState({ deleteDataConfirmButtonVisible: true });
        } else {
            this.setState({ deleteDataConfirmButtonVisible: false });
        }
    };

    handleOk = () => {
        const { deleteDataModalInput } = this.state;
        const { actions, driverId } = this.props;

        if (deleteDataModalInput && deleteDataModalInput.toLowerCase() === 'delete') {
            // make api request to delete data
            actions.deleteDriverDataRequest({ driverId });
        }
        this.setState({ isDeleteDataModalVisible: false });
    };

    handleCancel = () => {
        this.setState({ isDeleteDataModalVisible: false, deleteDataModalInput: null });
    };

    requestTag = () => {
        const { driverId, actions } = this.props;

        actions.requestTagRequest({ user_id: driverId });
    };

    setJourneyHistoryTabLoaded = () => {
        this.setState({ journeyHistoryTabLoaded: true });
    };

    setEventsHistoryTabLoaded = () => {
        this.setState({ eventsHistoryTabLoaded: true });
    };

    setServiceAlertsTabLoaded = () => {
        this.setState({ serviceAlertsTabLoaded: true });
    };

    onJourneyHistoryTabPageChange = (page, pageSize) => {
        const { match, actions } = this.props;
        const { journeyHistoryTabFilter } = this.state;

        const { driverId } = match.params;
        journeyHistoryTabFilter.page = page;

        if (journeyHistoryTabFilter.date_from && typeof journeyHistoryTabFilter.date_from === 'object') {
            journeyHistoryTabFilter.date_from = journeyHistoryTabFilter.date_from.format(isoStandardDateTimeFormat);
        }

        if (journeyHistoryTabFilter.date_to && typeof journeyHistoryTabFilter.date_to === 'object') {
            journeyHistoryTabFilter.date_to = journeyHistoryTabFilter.date_to.format(isoStandardDateTimeFormat);
        }

        this.setState({ journeyHistoryTabFilter });
        const params = { driverId, ...journeyHistoryTabFilter };
        actions.getDriverJourneyRequest(params);
    };

    onJourneyHistoryTabFilterChange = (values) => {
        const { match, actions } = this.props;

        const { driverId } = match.params;
        const filters = values;
        const {
            assets, date_from, date_to, journey_event, is_authorised,
        } = filters;
        filters.page = 1;
        this.setState({ journeyHistoryTabFilter: filters });
        const params = {
            driverId,
            page: 1,
            assets,
            journey_event,
            date_from: date_from && `${date_from.format('YYYY-MM-DD')} 00:00:00`,
            date_to: date_to && `${date_to.format('YYYY-MM-DD')} 23:59:59`,
            is_authorised,
        };
        actions.getDriverJourneyRequest(params);
    };

    onJourneyHistoryTabSortChange = (pagination, filters, sorter, extra) => {
        const { match, actions } = this.props;
        const { journeyHistoryTabFilter } = this.state;

        const { driverId } = match.params;
        const params = { orderBy: `${sorter.order == 'descend' ? '-' : ''}${sorter.field}`, driverId, ...journeyHistoryTabFilter };
        actions.getDriverJourneyRequest(params);
    };

    onEventsHistoryTabPageChange = (page, pageSize) => {
        const { match, actions } = this.props;
        const { eventsHistoryTabFilter } = this.state;

        const { driverId } = match.params;
        eventsHistoryTabFilter.page = page;
        this.setState({ eventsHistoryTabFilter });
        const params = { driverId, ...eventsHistoryTabFilter };
        actions.getDriverEventsRequest(params);
    };

    onEventsHistoryTabFilterChange = (values) => {
        const { match, actions } = this.props;

        const { driverId } = match.params;
        const filters = values;
        const { date_from, date_to } = filters;
        filters.date_from = date_from && date_from.format('YYYY-MM-DD 00:00:00');
        filters.date_to = date_to && date_to.format('YYYY-MM-DD 23:59:59');
        filters.date_range = null;
        filters.page = 1;
        this.setState({ eventsHistoryTabFilter: filters });
        const params = { driverId, page: 1, ...filters };
        actions.getDriverEventsRequest(params);
    };

    onEventsHistoryTabSortChange = (pagination, filters, sorter, extra) => {
        const { match, actions } = this.props;
        const { eventsHistoryTabFilter } = this.state;

        const { driverId } = match.params;
        const params = { orderBy: `${sorter.order == 'descend' ? '-' : ''}${sorter.field}`, driverId, ...eventsHistoryTabFilter };
        actions.getDriverEventsRequest(params);
    };

    onServiceAlertsTabPageChange = (page, pageSize) => {
        const { serviceAlertsTabFilter } = this.state;
        const { actions, match } = this.props;
        
        serviceAlertsTabFilter.page = page;
        const { driverId } = match.params;
        this.setState({ serviceAlertsTabFilter });
        const params = { driverId, ...serviceAlertsTabFilter };
        actions.getDriverEventsRequest(params);
    };

    onServiceAlertsTabFilterChange = (values) => {
        const { actions, match } = this.props;

        const filters = values;
        filters.page = 1;
        if (values.next_alert) {
            filters.next_alert = moment(values.next_alert, 'DD/MM/YYYY').format('YYYY-MM-DD');
        }
        this.setState({ serviceAlertsTabFilter: filters });
        const { driverId } = match.params;
        const params = { driverId, ...filters };
        actions.getDriverEventsRequest(params);
    };

    onServiceAlertsTabSortChange = (pagination, filters, sorter, extra) => {
        const { serviceAlertsTabFilter } = this.state;
        const { actions, match } = this.props;

        const { driverId } = match.params;
        const params = { driverId, orderBy: `${sorter.order == 'descend' ? '-' : ''}${sorter.field}`, ...serviceAlertsTabFilter };
        actions.getDriverEventsRequest(params);
    };

    toggleModalAssign = () => {
        const { modalAssignToAssets } = this.state;

        this.setState({
            modalAssignToAssets: !modalAssignToAssets,
        });
    };

    toggleModalEdit = () => {
        const { modalEdit } = this.state;

        this.setState({
            modalEdit: !modalEdit,
        });
    };

    updateDriverPicture(data) {
        const { actions, driverId } = this.props;

        actions.uploadImageRequest({ image: data, driverId });
    }

    selectAsset(id) {
        const { asset } = this.props;

        const singleAssetKey = findIndex(asset.assetList, (val) => val.id === id);
        if (singleAssetKey !== -1) {
            this.setState({
                selectedAsset: asset.assetList[singleAssetKey],
            });
        }
    }

    assignAsset() {
        const { selectedAsset } = this.state;
        const { driverId, actions } = this.props;

        let error = false;

        if (isEmpty(selectedAsset)) {
            error = true;
            message.error('Please select asset');
        }

        if (!error) {
            actions.assignAssetRequest({ assetId: selectedAsset.id, driverId });
            this.setState({
                modalAssignToAssets: false,
            });
        }
    }

    updateDriver = (values) => {
        const { actions, match } = this.props;
        
        const { driverId } = match.params;
        actions.updateDriverRequest({
            driverId,
            ...values,
        });
        this.setState({ modalEdit: true });
    };

    render() {
        return this.view();
    }
}

DriversView.propTypes = {
    actions: PropTypes.object.isRequired,
    driver: PropTypes.object.isRequired,
    asset: PropTypes.object.isRequired,
    driverId: PropTypes.number.isRequired,
    user: PropTypes.object.isRequired,
    driverJourneyFilters: PropTypes.object.isRequired,
};

function mapStateToProps(state, ownProps) {
    const driverId = parseInt(ownProps.match.params.driverId, 10);
    return {
        ...ownProps,
        driver: state.driver,
        asset: state.asset,
        driverId,
        tagStatus: state.driver.tagStatus,
        user: state.user,
        divisions: state.division.divisionOptions,
        driverList: state.driver.driverList,
        driverJourneyFilters: state.driver.driverJourneyFilters,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(
            {
                ...DriverActions,
                ...AssetActions,
                ...DivisionActions,
            },
            dispatch,
        ),
    };
}
export const DriversViewTest = DriversView;
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(DriversView);
